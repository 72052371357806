import React, { useCallback, useState, useMemo } from 'react';
import { useHistory } from 'react-router-dom';
import Lottie from 'react-lottie';
import { HiArrowNarrowRight } from 'react-icons/hi';
import stakingTerms from '~/assets/animations/staking-terms-details.json';
import { Modal, ModalBefore } from './styles';
import { web3store } from '../../store';
import ModalSwap from '~/components/ModalSwap';

import tokensClaimed from '~/assets/defaults/tokens-claimed.png';

interface IShow {
  showBefore?: boolean;
  handleCloseBefore?(): void;
  handleCancelBefore?(): void;
  showAfter?: boolean;
  handleCloseAfter?(): void;
  resultHash?: string;
}

const ModalTokensClaimed: React.FC<IShow> = ({
  showBefore,
  handleCloseBefore,
  handleCancelBefore,
  showAfter,
  handleCloseAfter,
  resultHash,
}) => {
  const history = useHistory();
  const [show, setShow] = useState(false);
  const explorerURL = process.env.REACT_APP_NETWORK_BLOCK_EXPLORER_URLS;
  const [balusdpi, setBalusdpi] = web3store.useState('balusdpi');

  const usdpi = useMemo(
    () => parseFloat((parseInt(balusdpi, 10) / 10 ** 18).toFixed(2)),
    [balusdpi]
  );

  const handleClaimTokens = useCallback(() => {
    setShow(true);
  }, []);

  const handleClose = useCallback(() => {
    setShow(false);
  }, []);

  return (
    <>
      <ModalBefore
        size="xl"
        show={showBefore}
        onHide={handleCancelBefore}
        className="modal-wrong-network"
      >
        <button
          type="button"
          className="h4 modal-close m-3 mb-0 ms-auto border-0 bg-transparent"
          onClick={handleCancelBefore}
        >
          x
        </button>
        <ModalBefore.Header className="justify-content-center border-0 pb-0 pb-lg-4 pt-5">
          <div className="my-n5 d-flex align-items-center justify-content-center overflow-hidden">
            <Lottie
              options={{
                animationData: stakingTerms,
                autoplay: true,
                loop: true,
                rendererSettings: {
                  preserveAspectRatio: 'xMidYMid slice',
                },
              }}
              height={220}
              width={300}
            />
          </div>
        </ModalBefore.Header>
        <ModalBefore.Body className="px-4 px-sm-5">
          <h2 className="mb-4 fw-bold text-center w-100">
            Before you claim...
          </h2>
          <div className="texts w-75 mx-auto">
            <p className="text-center px-4 mb-5">
              This is a blockchain transaction which requires a small gas
              transaction fee. Our ecosystem runs on USDPI therefore you will
              need some on your wallet to complete this transaction.
            </p>

            <div className="d-flex terms justify-content-between ps-5 pe-3 mb-2">
              <span>Your USDPI Balance:</span>
              <div className="d-flex align-items-center">
                <span className="color-span">${usdpi} USDPI</span>
                <ModalSwap btnText="Get more" className="ms-3 more" />
                {/* <button type="submit" className="ms-3 more">
                  Get more
                </button> */}
              </div>
            </div>

            <p className="text-center my-5">
              “Safe” Average network fee needed: <span>0.55 USDPI</span>
            </p>

            <button
              type="button"
              onClick={handleCloseBefore}
              className="btn-confirm w-100 mb-3"
            >
              Claim Tokens{' '}
              <HiArrowNarrowRight className="ms-3" color="#fff" size={28} />
            </button>

            <button
              type="button"
              onClick={handleCancelBefore}
              className="btn-cancel w-100 mb-3"
            >
              <span className="">Cancel</span>
            </button>
          </div>
        </ModalBefore.Body>
        <ModalBefore.Footer className="border-0 py-4" />
      </ModalBefore>
      <Modal
        size="xl"
        show={showAfter}
        onHide={handleCloseAfter}
        className="modal-wrong-network"
      >
        <button
          type="button"
          className="h4 modal-close m-3 mb-0 ms-auto border-0 bg-transparent"
          onClick={handleCloseAfter}
        >
          x
        </button>
        <Modal.Header className="justify-content-center border-0 pb-0 pb-lg-4 pt-5">
          <div className="mt-3 d-flex align-items-center justify-content-center overflow-hidden">
            <img src={tokensClaimed} alt="Tokens Claimed" />
          </div>
        </Modal.Header>
        <Modal.Body className="px-4 px-sm-5">
          <h2 className="mt-n3 mb-4 fw-bold text-center w-100">
            Tokens Claimed!
          </h2>
          <div className="texts w-75 mx-auto">
            <p className="text-center my-5 px-xl-2">
              Your PRFTI Tokens were successfully claimed and have been added to
              your wallet.
            </p>

            <p className="text-center">Here is the transaction hash:</p>
            <div className="mb-5">
              <a
                href={`${explorerURL}/tx/${resultHash}`}
                target="_blank"
                rel="noreferrer"
              >
                {resultHash}
              </a>
            </div>
            <button
              type="button"
              onClick={handleCloseAfter}
              className="btn-confirm w-100 mt-0 mb-4"
            >
              <span className="">Done</span>
            </button>
          </div>
        </Modal.Body>
        <Modal.Footer className="border-0 py-4" />
      </Modal>
    </>
  );
};

export default ModalTokensClaimed;
