import React, { useCallback, useState } from 'react';
import { BiArrowBack } from 'react-icons/bi';
import { Link } from 'react-router-dom';
import arrow from '~/assets/icons/arrow-bottom.svg';
import cubeTitle from '~/assets/defaults/the-cube-title.svg';
import { Container } from './styles';
import logoSwap from '~/assets/logo/logo-swap.png';
import logoPurple from '~/assets/logo/logo-p-purple.svg';

const CurrentPayout: React.FC = () => {
  const [activeCube, setActiveCube] = useState(false);
  const [manualAuto, setManualAuto] = useState('manual');
  const [playsAvaliable, setPlaysAvaliable] = useState(0);

  const playersTest = [
    { id: 1, avatar: logoPurple, name: 'NFT 093', value: '5.0' },
    { id: 2, avatar: logoPurple, name: 'NFT 883', value: '5.0' },
    { id: 3, avatar: logoPurple, name: 'NFT 112', value: '5.0' },
    { id: 4, avatar: logoPurple, name: 'NFT 008', value: '5.0' },
    { id: 5, avatar: logoPurple, name: 'NFT 656', value: '5.0' },
    { id: 6, avatar: logoPurple, name: 'NFT 320', value: '5.0' },
  ];

  const handleActiveCube = useCallback(() => {
    setActiveCube(!activeCube);
  }, [activeCube]);

  const handleTestPlays = useCallback(() => {
    setPlaysAvaliable(playsAvaliable + 1);
  }, [playsAvaliable]);

  const handleManualAuto = useCallback((e) => {
    setManualAuto(e);
  }, []);

  return (
    <Container className="pb-4">
      <div className="pt-4 btn-back">
        <Link
          to={`${process.env.PUBLIC_URL}/dashboard`}
          className="d-flex align-items-center ps-4"
        >
          <BiArrowBack size={30} color="#7D7777" className="me-4" />
          Back Home
        </Link>
      </div>
      <div className="d-flex justify-content-between pb-2 px-4 mt-4">
        <img src={cubeTitle} alt="Cube title" />
        <button
          type="button"
          onClick={handleActiveCube}
          className={`${
            activeCube && 'rotateActive'
          } bg-transparent border-0 arrow`}
        >
          <img src={arrow} alt="Arrow" />
        </button>
      </div>
      {activeCube && (
        <div className="px-4 px-xxl-5 pt-5">
          <span className="d-block payout">Current Payout</span>
          <span className="d-block payout-value">$2,667.00</span>
          <div className="manual-auto my-4">
            <button
              type="button"
              className={`${
                manualAuto === 'manual' && 'manual-auto-active'
              } w-50`}
              onClick={() => handleManualAuto('manual')}
            >
              <span>Manual</span>
            </button>
            <button
              type="button"
              className={`${
                manualAuto === 'auto' && 'manual-auto-active'
              } w-50`}
              onClick={() => handleManualAuto('auto')}
            >
              <span>Auto</span>
            </button>
          </div>
          {manualAuto === 'manual' && (
            <>
              <div className="d-flex entry justify-content-between mb-3">
                <span className="h6 text-white">Entry Amount</span>
                <span className="h6">$20.00</span>
              </div>
              <div className="d-flex bg-value justify-content-between align-items-center mb-3">
                <img src={logoSwap} alt="Image Value" className="m-2" />
                <span className="h5 text-white mb-0 pe-4">20</span>
              </div>
              <button
                type="button"
                className="w-100 bg-gradient-place mb-4"
                onClick={handleTestPlays}
              >
                PLACE BID
              </button>
              <div className="ps-3 mb-3">
                <span className="h6 avaliable">Free Plays Available: </span>
                <span className="h6 text-white">{playsAvaliable}</span>
              </div>
              <button
                type="button"
                className={`${
                  playsAvaliable <= 0 ? 'notActive' : 'bg-free-play'
                } w-100 mb-5`}
              >
                FREE PLAY
              </button>
              <div className="d-flex entry justify-content-between mb-3">
                <span className="h6 text-white">155 Players</span>
                <span className="h6">$2,667.00</span>
              </div>
              <div className="overflow-auto height pe-1">
                {playersTest.map((item) => (
                  <div
                    key={item.id}
                    className="d-flex align-items-center border-gray py-1 px-2 mt-1"
                  >
                    <img
                      src={item.avatar}
                      alt=""
                      className="avatar rounded-circle me-4"
                    />
                    <div className="w-50">
                      <span className="h6 text-gray">{item.name}</span>
                    </div>
                    <div className="w-25 text-end">
                      <span className="h6 text-gray">-</span>
                    </div>
                    <div className="w-25 text-end">
                      <span className="h6 text-white">{item.value}</span>
                    </div>
                  </div>
                ))}
              </div>
            </>
          )}
        </div>
      )}
    </Container>
  );
};

export default CurrentPayout;
