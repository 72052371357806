import { fromWei } from 'web3-utils';

export const { format: formatPrice } = new Intl.NumberFormat('en', {
  currency: 'USD',
  minimumFractionDigits: 2,
  maximumFractionDigits: 2,
});
export function formatEthValue(ethValueInWei: string): string {
  const ethValue = Number(fromWei(ethValueInWei, 'ether')); // Convert from wei to ETH
  const formattedEthValue = Intl.NumberFormat('en', {
    minimumFractionDigits: 2,
    maximumFractionDigits: 18,
  }).format(ethValue); // Format the ETH value with 18 decimal places

  return formattedEthValue;
}
