import React, { useCallback, useState } from 'react';
import { RiSendPlane2Fill } from 'react-icons/ri';
import { BsEmojiSmile } from 'react-icons/bs';
import { IoMdClose } from 'react-icons/io';
import EmojiPicker, { Theme, EmojiClickData } from 'emoji-picker-react';
import WalletRow from '~/components/WalletRow';
import { Container } from './styles';
import arrow from '~/assets/icons/arrow-bottom.svg';
import group from '~/assets/icons/chat-group-icon.svg';
import logoPurple from '~/assets/logo/logo-p-purple.svg';

const LiveChat: React.FC = () => {
  const [activeChat, setActiveChat] = useState(false);
  const [chatParticipants, setChatParticipants] = useState('chat');
  const [messageChat, setMessageChat] = useState([
    {
      me: false,
      participants: true,
      avatar: logoPurple,
      name: 'Avatar1',
      text: 'Hello Guys! Whats your opinion?',
      hour: '12:00 pm',
    },
    {
      me: false,
      participants: true,
      avatar: logoPurple,
      name: 'Avatar2',
      text: 'Images are better.',
      hour: '12:01 pm',
    },
    {
      me: true,
      participants: false,
      avatar: logoPurple,
      name: 'You',
      text: 'Yes, It will decrease the loading',
      hour: '12:03 pm',
    },
    {
      me: false,
      participants: true,
      avatar: logoPurple,
      name: 'Avatar3',
      text: 'Anyone is up for illustrations. I think there are less relatable images according to our brand.',
      hour: '12:05 pm',
    },
  ]);

  const [meMessage, setMeMessage] = useState('');
  const [keyPress, setKeyPress] = useState('');

  const [emojiWindowActive, setEmojiWindowActive] = useState(false);

  const time = new Date().toLocaleTimeString().slice(0, -3);

  const elementClass = document.querySelector('.height-chat');

  if (elementClass) {
    const height = elementClass.scrollHeight;
    elementClass.scrollTo(0, height);
  }

  const handleButtonOpenEmoji = useCallback(() => {
    setEmojiWindowActive(!emojiWindowActive);
  }, [emojiWindowActive]);

  const handlePicker = useCallback(
    (emojiData: EmojiClickData) => {
      setMeMessage(meMessage + emojiData.emoji);
      setKeyPress('');
    },
    [meMessage]
  );

  const cleanMeMessage = useCallback(() => {
    setMeMessage('');
    setKeyPress('');
  }, []);

  const handleMeMessage = useCallback((e) => {
    setMeMessage(e);
  }, []);

  const handleSendMessage = useCallback(() => {
    setMessageChat([
      ...messageChat,
      {
        me: true,
        participants: false,
        avatar: logoPurple,
        name: 'You',
        text: meMessage,
        hour: time,
      },
    ]);
    cleanMeMessage();
  }, [messageChat, meMessage, time, cleanMeMessage]);

  const handleKeyPress = useCallback((e) => {
    setKeyPress(e);
  }, []);

  if (keyPress === 'Enter' && meMessage !== '') {
    handleSendMessage();
  }

  const handleActiveChat = useCallback(() => {
    setActiveChat(!activeChat);
  }, [activeChat]);

  const handleChatParticipants = useCallback((e) => {
    setChatParticipants(e);
  }, []);

  return (
    <Container className="py-3">
      <div className="d-flex justify-content-center">
        <WalletRow />
      </div>
      <div className="d-flex justify-content-between align-items-center px-4 pt-4 pb-2">
        <div className="d-flex justify-content-between align-items-center">
          <span className="title d-flex align-items-center me-3">
            Live Chat
          </span>

          <span className="h6 fw-600 text-white mb-0">
            <img src={group} alt="Icon group" /> 2,122
          </span>
        </div>
        <button
          type="button"
          onClick={handleActiveChat}
          className={`${
            activeChat && 'rotateActive'
          } bg-transparent border-0 arrow`}
        >
          <img src={arrow} alt="Arrow" />
        </button>
      </div>
      {activeChat && (
        <>
          <div className="chat my-4">
            <button
              type="button"
              className={`${chatParticipants === 'chat' && 'chat-active'} mx-3`}
              onClick={() => {
                handleChatParticipants('chat');
              }}
            >
              <span>Chat</span>
            </button>
            {/* <button
              type="button"
              className={`${
                chatParticipants === 'participants' && 'chat-active'
              } mx-3`}
              onClick={() => handleChatParticipants('participants')}
            >
              <span>Participants</span>
            </button> */}
          </div>
          {chatParticipants === 'chat' && (
            <>
              <div
                className={`px-4 me-2 overflow-auto ${
                  emojiWindowActive ? 'vh-26 mb-4' : 'vh-61'
                }`}
              >
                {messageChat.map((element) => (
                  <div
                    className={`${
                      element.me && 'justify-content-end'
                    } d-flex messages mt-4`}
                  >
                    {element.me ? (
                      <div className="me-3 no-avatar" />
                    ) : (
                      <img src={element.avatar} alt="Avatar" className="me-3" />
                    )}
                    <div className={`${element.me && 'text-end'}`}>
                      <span className="d-block h6 text-white">
                        {element.name}
                      </span>

                      <div
                        className={`px-4 py-3 ${
                          element.me ? 'bg-you' : 'bg-participants'
                        }`}
                      >
                        <p className="text-white h6">{element.text} </p>
                        <span className="hour d-block text-end">
                          {element.hour}
                        </span>
                      </div>
                    </div>
                  </div>
                ))}{' '}
              </div>
              <div className="px-4">
                <EmojiPicker
                  onEmojiClick={handlePicker}
                  autoFocusSearch={false}
                  searchDisabled
                  skinTonesDisabled
                  theme={Theme.DARK}
                  width="100%"
                  height={emojiWindowActive ? 350 : 0}
                />
              </div>
              <div className="d-flex mt-3 px-4">
                <div className="bg-input p-3 flex-grow-1 d-flex">
                  <input
                    className="bg-transparent border-0 text-white flex-grow-1 w-100"
                    name=""
                    id=""
                    placeholder="Write message here"
                    onChange={(e) => {
                      handleMeMessage(e.target.value);
                    }}
                    onKeyPress={(e) => {
                      handleKeyPress(e.key);
                    }}
                    value={meMessage}
                  />
                  <button
                    type="button"
                    className="border-0 p-2 ms-3"
                    onClick={handleSendMessage}
                    disabled={meMessage === ''}
                  >
                    <RiSendPlane2Fill size={25} color="#18191A" />
                  </button>
                </div>
                <div className="ms-2">
                  <button
                    type="button"
                    className="bg-input border-0 h-100 px-4"
                    onClick={handleButtonOpenEmoji}
                  >
                    {emojiWindowActive ? (
                      <IoMdClose size={25} color="rgba(255, 255, 255, 0.8)" />
                    ) : (
                      <BsEmojiSmile
                        size={25}
                        color="rgba(255, 255, 255, 0.8)"
                      />
                    )}
                  </button>
                </div>
              </div>
            </>
          )}
        </>
      )}
    </Container>
  );
};

export default LiveChat;
