import React, { useCallback, useMemo, useState } from 'react';
import { ApexOptions } from 'apexcharts';
import ReactApexChart from 'react-apexcharts';
import arrow from '~/assets/icons/arrow-bottom.svg';
import { Container, Graph } from './styles';
import logoPurple from '~/assets/logo/logo-p-purple.svg';
import { formatPrice } from '~/utils/format';

const WinStats: React.FC = () => {
  const [months, setMonths] = useState<string[]>([]);
  const [monthCommissions, setMonthCommissions] = useState<number[]>([]);

  const [activeWin, setActiveWin] = useState(false);
  const options = useMemo(() => {
    const data: ApexOptions = {
      chart: {
        height: 350,
        type: 'line',
        toolbar: {
          show: false,
        },
        selection: {
          enabled: false,
        },
        zoom: {
          enabled: false,
        },
      },
      dataLabels: {
        enabled: false,
      },
      stroke: {
        curve: 'smooth',
        width: 3,
      },
      xaxis: {
        type: 'category',
        categories: months,
      },
      yaxis: {
        labels: {
          formatter: (value: any) => {
            return formatPrice(value);
          },
        },
      },
      tooltip: {
        x: {
          format: 'dd/MM/yy HH:mm',
        },
        theme: 'dark',
      },
      fill: {
        colors: ['#79D1F8'],
        type: 'gradient',
        gradient: {
          gradientToColors: ['#79D1F8'],
          shadeIntensity: 1,
          opacityFrom: 1,
          opacityTo: 1,
          stops: [0, 100],
        },
      },
      colors: ['#E323FF'],
      markers: {
        colors: ['#B66CFB'],
        strokeColors: 'rgba(255, 255, 255, 0.5)',
      },
      grid: {
        show: false,
      },
    };

    return data;
  }, [months]);

  const series = useMemo(
    () => [
      {
        name: 'Ganhos',
        data: monthCommissions,
      },
    ],
    [monthCommissions]
  );

  const handleActiveWin = useCallback(() => {
    setActiveWin(!activeWin);
  }, [activeWin]);
  return (
    <Container className="pt-4">
      <div className="d-flex justify-content-between align-items-center px-4 pb-4">
        <span className="stats">Win Stats</span>
        <button
          type="button"
          onClick={handleActiveWin}
          className={`${
            activeWin && 'rotateActive'
          } bg-transparent border-0 arrow`}
        >
          <img src={arrow} alt="Arrow" />
        </button>
      </div>
      {activeWin && (
        <>
          <div className="d-flex px-3">
            <div className="w-50 bg-win p-3">
              <span className="h6 text-white ps-3">Profit on Win</span>
              <div className="d-flex align-items-center justify-content-between value-profiti p-2 mt-2">
                <img src={logoPurple} alt="Logo" />
                <span>1,145.00</span>
              </div>
            </div>
            <div className="w-25 bg-win p-3 text-center ms-2">
              <span className="h6 text-white d-block mb-3">Wins</span>
              <span className="h6 text-gradient d-block mb-0">7</span>
            </div>
            <div className="w-25 bg-win p-3 text-center ms-2">
              <span className="h6 text-white d-block mb-3">Plays</span>
              <span className="h6 text-gray d-block mb-0">113</span>
            </div>
          </div>
          <div className="mt-2 pb-2 px-3">
            <Graph className="mb-2">
              <div className="h6 small text-white ps-3">Win Chart</div>
              <ReactApexChart type="line" options={options} series={series} />
            </Graph>
          </div>
        </>
      )}
    </Container>
  );
};

export default WinStats;
