import React, { useState, useCallback, useEffect, useMemo } from 'react';
import { useLocation } from 'react-router-dom';
import Swal from 'sweetalert2';
import Slider from 'react-slick';
import Lottie from 'react-lottie';
import { useAuth } from '~/hooks/Auth';
import api from '~/services/api';
import { web3store } from '../../store';
import earningsDone from '~/assets/animations/earnings-done.json';

import { Container, Modal } from './styles';
import ModalRetireMoney, {
  IFormData as ModalRetireMoneyFormData,
} from '../ModalRetireMoney';
import { formatPrice } from '~/utils/format';
import swalError from '~/utils/swalError';
import aiverifyGif from '~/assets/animations/aiverify.gif';
import logoWhite from '~/assets/logo/logo-p-white.svg';
import Loading from '~/components/Loading';

interface IWallet {
  id: string;
  amount: number;
  cripto_address: string;
}

interface ICenter {
  id: number;
  earnings: string;
}

interface IEarning {
  total: string;
  centers: ICenter[];
}

interface IEarningResponse {
  earnings: {
    day: IEarning;
    wtd: IEarning;
    mtd: IEarning;
    ytd: IEarning;
    available: string;
  };
}
const MyEarnings: React.FC = () => {
  const { user } = useAuth();
  const location = useLocation();
  const [showModal, setShowModal] = useState(false);
  const [show, setShow] = useState(false);
  const [loading, setLoading] = useState(false);
  const [active, setActive] = useState('Day');
  const [activeCenter, setActiveCenter] = useState(0);
  const [userChanged, setUserChanged] = useState(false);
  const [runWithdrawal, setRunWithdrawal] = web3store.useState('runWithdrawal');
  const [withdrawalResult, setWithdrawalResult] =
    web3store.useState('withdrawalResult');
  const [walletAddPRFTI, setWalletAddPRFTI] =
    web3store.useState('walletAddPRFTI');
  const [walletAddUSDPI, setWalletAddUSDPI] =
    web3store.useState('walletAddUSDPI');
  const [addPRFTI, setAddPRFTI] = useState(false);
  const [addUSDPI, setAddUSDPI] = useState(false);
  const [fetchData, setFetchData] = useState(false);
  const explorerURL = process.env.REACT_APP_NETWORK_BLOCK_EXPLORER_URLS;
  const [chainMode, setChainMode] = web3store.useState('chainMode');
  const [earningsDay, setEarningsDay] = useState<IEarning>({
    total: '0.00',
    centers: [],
  });
  const [earningsWtd, setEarningsWtd] = useState<IEarning>({
    total: '0.00',
    centers: [],
  });
  const [earningsMtd, setEarningsMtd] = useState<IEarning>({
    total: '0.00',
    centers: [],
  });
  const [earningsYtd, setEarningsYtd] = useState<IEarning>({
    total: '0.00',
    centers: [],
  });
  const [earnings, setEarnings] = useState<IEarning>({
    total: '0.00',
    centers: [],
  });
  const [available, setAvailable] = useState('0.00');
  const [wallet, setWallet] = useState<IWallet>({} as IWallet);

  const filterCalendar = useMemo(() => ['Day', 'Week', 'Month', 'Year'], []);

  const settings = useMemo(
    () => ({
      dots: true,
      infinite: false,
      arrows: false,
      speed: 500,
      slidesToShow: 4,
      slidesToScroll: 2,
    }),
    []
  );

  useEffect(() => {
    if (user && (fetchData || userChanged)) {
      api
        .get<IEarningResponse>(`/v1/member/earningtotals/${user?.id}`)
        .then((response) => {
          console.log(response.data);
          const centersDay = response.data.earnings.day.centers.map<ICenter>(
            (center) => ({
              id: center.id,
              earnings: formatPrice(
                parseFloat(
                  (parseInt(center.earnings, 10) / 10 ** 18).toFixed(2)
                )
              ),
            })
          );
          setEarnings({
            total: formatPrice(
              parseFloat(
                (
                  parseInt(response.data.earnings.day.total, 10) /
                  10 ** 18
                ).toFixed(2)
              )
            ),
            centers: centersDay,
          });
          setEarningsDay({
            total: formatPrice(
              parseFloat(
                (
                  parseInt(response.data.earnings.day.total, 10) /
                  10 ** 18
                ).toFixed(2)
              )
            ),
            centers: centersDay,
          });

          const centersWtd = response.data.earnings.wtd.centers.map<ICenter>(
            (center) => ({
              id: center.id,
              earnings: formatPrice(
                parseFloat(
                  (parseInt(center.earnings, 10) / 10 ** 18).toFixed(2)
                )
              ),
            })
          );
          setEarningsWtd({
            total: formatPrice(
              parseFloat(
                (
                  parseInt(response.data.earnings.wtd.total, 10) /
                  10 ** 18
                ).toFixed(2)
              )
            ),
            centers: centersWtd,
          });

          const centersMtd = response.data.earnings.mtd.centers.map<ICenter>(
            (center) => ({
              id: center.id,
              earnings: formatPrice(
                parseFloat(
                  (parseInt(center.earnings, 10) / 10 ** 18).toFixed(2)
                )
              ),
            })
          );
          setEarningsMtd({
            total: formatPrice(
              parseFloat(
                (
                  parseInt(response.data.earnings.mtd.total, 10) /
                  10 ** 18
                ).toFixed(2)
              )
            ),
            centers: centersMtd,
          });

          const centersYtd = response.data.earnings.ytd.centers.map<ICenter>(
            (center) => ({
              id: center.id,
              earnings: formatPrice(
                parseFloat(
                  (parseInt(center.earnings, 10) / 10 ** 18).toFixed(2)
                )
              ),
            })
          );
          setEarningsYtd({
            total: formatPrice(
              parseFloat(
                (
                  parseInt(response.data.earnings.ytd.total, 10) /
                  10 ** 18
                ).toFixed(2)
              )
            ),
            centers: centersYtd,
          });

          setAvailable(
            formatPrice(
              parseFloat(
                (
                  parseInt(response.data.earnings.available, 10) /
                  10 ** 18
                ).toFixed(2)
              )
            )
          );
        })
        .finally(() => {
          setFetchData(false);
          setUserChanged(false);
        });
    }
  }, [user, fetchData, userChanged]);

  useEffect(() => {
    setUserChanged(true);
  }, [user]);

  const handleActiveCalendar = useCallback(
    (calendar: string) => {
      if (calendar === 'Day') {
        setEarnings(earningsDay);
      }

      if (calendar === 'Week') {
        setEarnings(earningsWtd);
      }

      if (calendar === 'Month') {
        setEarnings(earningsMtd);
      }

      if (calendar === 'Year') {
        setEarnings(earningsYtd);
      }

      setActive(calendar);
    },
    [earningsDay, earningsMtd, earningsWtd, earningsYtd]
  );

  const handleActiveCenter = (center_id: number) => {
    setActiveCenter(center_id);
  };

  const handleShow = useCallback(() => {
    setShow(true);
  }, []);

  const handleWithdraw = useCallback(async () => {
    console.log(available);
    // console.log(typeof available);
    // console.log(parseFloat(available));
    const amtFloat = parseFloat(available.replace(/,/g, ''));
    // console.log(amtFloat);
    if (amtFloat > 1) {
      setLoading(true);
      setRunWithdrawal(true);
    }
  }, [available]);

  useEffect(() => {
    setLoading(false);
    if (withdrawalResult.length > 0 && withdrawalResult !== 'ERROR') {
      handleShow();
      setFetchData(true);
    } else {
      setWithdrawalResult('');
    }
  }, [withdrawalResult]);

  const handleClose = useCallback(() => {
    setShowModal(false);
    setShow(false);
  }, []);

  const handleClickPRFTIYes = useCallback(() => {
    setWalletAddPRFTI(true);
    setAddPRFTI(false);
  }, [setWalletAddPRFTI]);

  const handleClickUSDPIYes = useCallback(() => {
    setWalletAddUSDPI(true);
    setAddUSDPI(false);
  }, [setWalletAddUSDPI]);

  const handleSubmit = useCallback(
    async (data: ModalRetireMoneyFormData) => {
      try {
        // const response = await api.post('wallets/withdraw', data);
        Swal.fire(
          'Tudo certo!',
          'Saque realizado com sucesso.',
          'success'
        ).then(() => {
          // setWallet(response.data);
          handleClose();
        });
      } catch (error) {
        const err: any = error;
        swalError({
          message: err.response.data.message,
          textButton: 'Close',
        });
      }
    },
    [handleClose]
  );
  // On Opern
  useEffect(() => {
    setChainMode('NETWORK');
    return () => {
      // This function will run when the component unmounts
      setChainMode('');
    };
  }, []);

  return (
    <Container className="p-3 py-5 h-100">
      <div className="row justify-content-center">
        <div className="col-lg-12">
          <small className="TitleBox font-weight-bold">Total Earnings</small>
        </div>
        <div className="col-lg-12">
          <span className="ValueBox">${earnings.total}</span>
        </div>
        <div className="col-lg-12 TimeBox">
          <div className="d-flex justify-content-around">
            {filterCalendar.map((calendar) => (
              <button
                type="button"
                onClick={() => handleActiveCalendar(calendar)}
                className={`border-0 bg-transparent ItemTimeBox ${
                  active === calendar && 'Active'
                }`}
              >
                {calendar}
              </button>
            ))}
          </div>
        </div>
        <div className="col-lg-12 text-center MonthBox">
          <Slider {...settings}>
            {earnings.centers.map((center) => (
              <button
                type="button"
                className={`border-0 bg-transparent rounded-pill ItemMonthBox w-25 ${
                  activeCenter === center.id && 'Active'
                }`}
                onClick={() => handleActiveCenter(center.id)}
              >
                {`Center ${center.id}`}
              </button>
            ))}
          </Slider>
        </div>
        <div className="col-11 col-sm-10 p-3 py-5 mt-5 WithDrawBox">
          <div className="row justify-content-center">
            <div className="col-12 text-center">
              <div className="row">
                <div className="col-sm-12 col-lg-12 TitleWithDrawBox">
                  <small>Available&nbsp;Earnings</small>
                </div>
              </div>
              <div className="ValueWithDrawBox mt-3 mb-4">
                <span className="h3 font-weight-400">${available}</span>
              </div>
            </div>

            {/* david asked not to climb this part yet /david pediu para deixar essa parte oculta

            <div className="col-12 px-5 mb-3">
              <div className="bg-box-input amount-stake">
                <div className="bd-input d-flex justify-content-between mt-3 p-3">
                  <input
                    type="number"
                    placeholder="Enter the amount you wish to claim"
                    className="w-90"
                    name=""
                    id=""
                  />
                  <button type="button" className="btn-max px-3 ms-3">
                    Max
                  </button>
                </div>
              </div>
            </div> */}

            <div className="col-12 px-3 px-5 mt-3 ButtonBox">
              <div className="border-gradient-light w-100 border-rad">
                <button
                  className="btn ButtonWithDrawBox w-100"
                  type="button"
                  onClick={() => {
                    handleWithdraw();
                    // handleShow();
                  }}
                >
                  Claim
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Modal
        size="xl"
        show={show}
        onHide={handleClose}
        className="modal-wrong-network"
      >
        <button
          type="button"
          className="h4 modal-close m-3 mb-0 ms-auto border-0 bg-transparent"
          onClick={handleClose}
        >
          x
        </button>
        <Modal.Header className="justify-content-center border-0 pb-0 pb-lg-4 pt-5">
          <div className="my-n5 d-flex align-items-center justify-content-center overflow-hidden">
            <Lottie
              options={{
                animationData: earningsDone,
                autoplay: true,
                loop: false,
                rendererSettings: {
                  preserveAspectRatio: 'xMidYMid slice',
                },
              }}
              height={220}
              width={300}
            />
          </div>
        </Modal.Header>
        <Modal.Body className="px-4 px-sm-5">
          <h2 className="mb-4 fw-bold text-center w-100">
            Earnings Successfully Claimed!
          </h2>
          <div className="texts w-75 mx-auto">
            <p className="text-center mb-4 px-xl-5">
              Your earnings were successfully claimed from the smart contract to
              your MetaMask&nbsp;wallet.
            </p>
            <p className="text-center">Here is the transaction hash:</p>
            <div className="mb-5">
              <a
                href={`${explorerURL}/tx/${withdrawalResult}`}
                target="_blank"
                rel="noreferrer"
              >
                {withdrawalResult}
              </a>
            </div>
            <p className="mb-4">
              Use the buttons below to add the Token contract to your MetaMask
              if you have not done it yet.
            </p>
            <button
              type="button"
              className="btn-add-usdpi w-100 mb-4"
              onClick={handleClickUSDPIYes}
            >
              <span className="">Add USDPI To Wallet</span>
            </button>
            <button
              type="button"
              className="btn-add-prfti w-100 mb-3"
              onClick={handleClickPRFTIYes}
            >
              <span className="">Add PRFTI To Wallet</span>
            </button>
          </div>
        </Modal.Body>
        <Modal.Footer className="border-0 py-4" />
      </Modal>
      <Loading
        type="dark"
        srcImg={logoWhite}
        text="PLEASE WAIT..."
        active={loading}
      />
    </Container>
  );
};

export default MyEarnings;
