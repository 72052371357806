import React, { useState, useMemo, useEffect, useCallback } from 'react';
import { format, parseISO } from 'date-fns';

import Table from '~/components/Table';
import api from '~/services/api';
import { formatPrice } from '~/utils/format';
import { useAuth } from '~/hooks/Auth';

interface ITransactionResponse {
  transactions: {
    total_count: number;
    transactions: {
      id: number;
      type: string;
      date: number;
      amount: string;
      txid: string;
    }[];
  };
}

interface ITransaction {
  id: number;
  type: string;
  date: string;
  amount: string;
}

const TransactionHistory: React.FC = () => {
  const [coin, setCoin] = useState('usdpi');
  const { user } = useAuth();
  const [transactions, setTransactions] = useState<ITransaction[]>([]);

  useEffect(() => {
    api
      .get<ITransactionResponse>(`/v1/member/transaction/history/${user?.id}`)
      .then(async (response) => {
        const data = response.data.transactions.transactions.map<ITransaction>(
          (transaction) => ({
            id: transaction.id,
            type: transaction.type,
            date: format(new Date(transaction.date), 'dd-MMM-yy'),
            amount: formatPrice(
              parseFloat(
                (parseInt(transaction.amount, 10) / 10 ** 18).toFixed(2)
              )
            ),
          })
        );
        setTransactions(data);
      });
  }, [user]);

  const transactionsTest = [
    { type: 'Profit Cycle 3', date: '02/25/2022', amount: '$500.00' },
    { type: 'Profit Cycle 4', date: '02/25/2022', amount: '$300.00' },
  ];

  const columns = useMemo(
    () => [
      {
        name: 'Type',
        selector: 'type',
        sortable: true,
      },
      {
        name: 'Date',
        selector: 'date',
        sortable: true,
      },
      {
        name: 'Amount',
        selector: 'amount',
        sortable: true,
      },
    ],
    []
  );

  const handleCoin = useCallback((e) => {
    setCoin(e);
  }, []);

  return (
    <>
      <div className="position-relative d-flex flex-column h-100 justify-content-between">
        <div className="position-absolute btns-history">
          <button
            type="button"
            className={`py-2 px-5 active-${coin === 'usdpi' ? 'usdpi' : ''}`}
            onClick={() => handleCoin('usdpi')}
          >
            USDPI
          </button>
          <button
            type="button"
            className={`py-2 px-5 active-${coin === 'prfti' ? 'prfti' : ''}`}
            onClick={() => handleCoin('prfti')}
          >
            PRFTI
          </button>
        </div>

        <Table
          columns={columns}
          data={coin === 'usdpi' ? transactions : transactionsTest}
          pagination
          title="History"
        />
      </div>
    </>
  );
};

export default TransactionHistory;
