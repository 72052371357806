/* eslint-disable import/no-duplicates */
import React, {
  useState,
  useMemo,
  useEffect,
  useCallback,
  useLayoutEffect,
  useRef,
} from 'react';
import ReactApexChart from 'react-apexcharts';
import Slider from 'react-slick';
import Dropdown from 'react-dropdown';
import { Link } from 'react-router-dom';
import { MdFindInPage } from 'react-icons/md';
import { RxCalendar } from 'react-icons/rx';

import { eachMonthOfInterval, format } from 'date-fns';
import ptBR from 'date-fns/locale/pt-BR';

import { ApexOptions } from 'apexcharts';
import { render } from 'react-dom';
import { web3store } from '../../store';
import { formatPrice } from '~/utils/format';
import { useAuth } from '~/hooks/Auth';
import abbreviate from '~/utils/abbreviate';

import { Container, Welcome, Content, Graph } from './styles';
import Copy from '~/components/Copy';
import WalletRow from '~/components/WalletRow';
import membersIcon from '~/assets/icons/members-icon.svg';
import totalMembersIcon from '~/assets/icons/total-members-icon.svg';
import coin from '~/assets/icons/coin-icon.svg';
import rain from '~/assets/icons/rain-icon.svg';

import search from '~/assets/icons/search-icon.svg';
import news from '~/assets/defaults/news.png';

import api from '~/services/api';
import ModalCongratulations from '~/components/ModalCongratulations';
import swalError from '~/utils/swalError';
import { Avatar } from '~/components/WalletAvatar/styles';

interface ICommission {
  id: string;
  earning: string;
  created_at: string;
}

interface IAvatar {
  id: string;
  avatar_url: string;
}

interface IUser {
  id: string;
  name: string;
  created_at: string;
  avatar?: IAvatar;
}

interface IOrder {
  id: string;
  user: IUser;
}

interface IReferrer {
  id: string;
  order: IOrder;
}

interface IMember {
  id: string;
  avatar: string;
  name: string;
  created_at: string;
}

interface IReferrer {
  id: string;
  name: string;
  nft_img: string;
  rank: string;
  status: string;
  date_registered: number;
}

interface IVolumeCenter {
  idx: number;
  center: number;
  amount: string;
}

interface IEarningResponse {
  earnings: {
    id: string;
    total_count: number;
    profit_centers: IVolumeCenter[];
  };
}

const Dashboard: React.FC = () => {
  const { user } = useAuth();
  const [loading, setLoading] = useState(false);
  const [volumeCenters, setVolumeCenters] = useState<IVolumeCenter[]>([]);

  const [show, setShow] = useState(false);
  const [referrers, setRefferers] = useState<IReferrer[]>([]);
  const [dataPointindex, setDataPointindex] = useState<number | undefined>(
    undefined
  );
  const [totalEarned, setTotalEarned] = useState('0.00');
  const [connectedMember, setConnectedMember] =
    web3store.useState('connectedMember');

  const months = useMemo<string[]>(() => {
    return eachMonthOfInterval({
      start: new Date(2022, 0),
      end: new Date(2022, 11),
    }).map((date) => {
      return format(date, 'MMM');
    }, []);
  }, []);

  const settings = useMemo(
    () => ({
      dots: true,
      infinite: true,
      arrows: true,
      speed: 500,
      slidesToShow: 3,
      slidesToScroll: 3,
    }),
    []
  );

  // const teste = [
  //   {
  //     id: '1',
  //     name: 'teste1',
  //     nft_img: coin,
  //     rank: '1',
  //     status: 'new',
  //     date_registered: '01122023',
  //   },
  //   {
  //     id: '2',
  //     name: 'teste2',
  //     nft_img: coin,
  //     rank: '1',
  //     status: 'new',
  //     date_registered: '01122023',
  //   },
  //   {
  //     id: '3',
  //     name: 'teste1',
  //     nft_img: coin,
  //     rank: '1',
  //     status: 'new',
  //     date_registered: '01122023',
  //   },
  //   {
  //     id: '4',
  //     name: 'teste2',
  //     nft_img: coin,
  //     rank: '1',
  //     status: 'new',
  //     date_registered: '01122023',
  //   },
  //   {
  //     id: '5',
  //     name: 'teste1',
  //     nft_img: coin,
  //     rank: '1',
  //     status: 'new',
  //     date_registered: '01122023',
  //   },
  //   {
  //     id: '6',
  //     name: 'teste1',
  //     nft_img: coin,
  //     rank: '1',
  //     status: 'new',
  //     date_registered: '01122023',
  //   },
  // ];

  useEffect(() => {
    if (connectedMember !== null) {
      api
        .get(`/v1/member/referrals/${connectedMember.memberId}`)
        .then(async (response) => {
          const responseEarnings = await api.get<IEarningResponse>(
            `/v1/member/earnings/${user?.id}`
          );

          const data =
            responseEarnings.data.earnings.profit_centers.map<IVolumeCenter>(
              (profitCenter) => ({
                idx: profitCenter.idx,
                center: profitCenter.idx,
                // center: profitCenter.center,
                amount: formatPrice(
                  parseFloat(
                    (parseInt(profitCenter.amount, 10) / 10 ** 18).toFixed(2)
                  )
                ),
              })
            );

          setRefferers(response.data.referrals.referrals);
          setVolumeCenters(data);
        });
    }
  }, [user]);

  useLayoutEffect(() => {
    if (localStorage.getItem('wasNoWish') === 'noWish') {
      setShow(true);
    } else {
      setShow(false);
    }
  }, [show]);

  const name = useMemo(() => user?.screen_name, [user]);

  const gradientToColors = useMemo(() => {
    const data: string[] = [];
    for (let index = 0; index < 12; index += 1) {
      data.push(
        index === dataPointindex
          ? 'rgba(0, 249, 171, 0.15)'
          : 'rgba(51, 54, 56, 0.15)'
      );
    }

    return data;
  }, [dataPointindex]);

  const options = useMemo(() => {
    const data: ApexOptions = {
      chart: {
        height: 250,
        type: 'bar',
        toolbar: {
          show: false,
        },

        events: {
          dataPointSelection: (e, chart, { dataPointIndex }) => {
            if (dataPointIndex === dataPointindex) {
              setDataPointindex(undefined);
            } else {
              setDataPointindex(dataPointIndex);
            }
          },
        },

        foreColor: '#747474',
        selection: {
          enabled: false,
        },
        zoom: {
          enabled: false,
        },
      },
      dataLabels: {
        enabled: false,
      },

      plotOptions: {
        bar: {
          distributed: true,
          borderRadius: 6,
        },
      },

      xaxis: {
        type: 'category',
        categories: months,
        crosshairs: { show: false },
        axisTicks: { show: false },
      },
      yaxis: {
        labels: {
          formatter: (value: any) => {
            return formatPrice(value);
          },
        },
      },
      tooltip: {
        x: {
          format: 'dd/MM/yy HH:mm',
        },
        theme: 'dark',
      },

      colors: [
        ({ dataPointIndex }: any) => {
          if (dataPointIndex === dataPointindex) {
            return '#00f9ab';
          }
          return '#333638';
        },
      ],

      fill: {
        opacity: 1,
        type: 'gradient',
        gradient: {
          gradientToColors,
          type: 'vertical',
          shadeIntensity: 1,
          opacityFrom: 1,
          opacityTo: 1,
          stops: [0, 100],
        },
      },

      states: {
        hover: {
          filter: {
            type: 'lighten',
            value: 0.05,
          },
        },
      },

      grid: {
        show: false,
      },
      legend: {
        show: false,
      },
    };

    return data;
  }, [months, gradientToColors, dataPointindex]);

  const series = useMemo(
    () => [
      {
        name: 'Earnings',
        data: [10, 20, 5, 30, 60, 20, 55, 40, 10, 60, 70, 30],
      },
    ],
    []
  );

  const referralCode = useMemo(() => {
    return user?.referral_link;
  }, [user]);

  const dropOptions = useMemo(
    () => [
      {
        value: '',
        label: 'Lifetime',
      },
      {
        value: 'TODAY',
        label: 'Today',
      },
      {
        value: 'WTD',
        label: 'This week',
      },
      {
        value: 'MTD',
        label: 'This month',
      },
      {
        value: 'YTD',
        label: 'This year',
      },
    ],
    []
  );

  useEffect(() => {
    console.log('connectedMember');
    console.log(connectedMember);
    if (connectedMember !== null) {
      //
      api
        .get(`v1/member/dashboard/earnings/${connectedMember.memberId}/YTD`)
        .then((response) => {
          setTotalEarned(
            formatPrice(
              parseFloat((parseInt(response.data, 10) / 10 ** 18).toFixed(2))
            )
          );
        });
    }
  }, []);

  const handleChange = useCallback(
    async (e) => {
      if (user) {
        const response = await api.get(
          `v1/member/dashboard/earnings/${connectedMember.memberId}/${e.value}`
        );
        setTotalEarned(
          formatPrice(
            parseFloat((parseInt(response.data, 10) / 10 ** 18).toFixed(2))
          )
        );
      }
    },
    [user]
  );

  return (
    <Container>
      {user && Object.keys(user).length > 0 && (
        <>
          <div className="container-fluid container-xxl">
            <div className="row mt-4">
              <div className="col-12 mb-2">
                <Welcome>
                  <div className="container-fluid">
                    <div className="row justify-content-center justify-content-lg-start align-items-center mb-lg-4">
                      <div className="col-sm-11 col-lg-6 px-0">
                        <h1 className="h3 h2-lg text-center text-sm-start mt-4 mb-0 mt-lg-0 fw-bold">
                          Hi, {name}!
                        </h1>
                      </div>
                      <div className="col-lg-6 px-0">
                        <WalletRow />
                      </div>
                    </div>
                  </div>
                </Welcome>
              </div>
              <div className="col">
                <div className="row justify-content-center">
                  <div className="col-sm-11 col-lg-7 ps-lg-0 my-2 my-lg-0">
                    <Content className="h-100">
                      <Graph className="position-relative">
                        <div className="d-flex justify-content-between ps-3">
                          <div className="total">
                            <span>Total Earned</span>
                            <br />
                            <span>$ {totalEarned}</span>
                          </div>
                          <div className="d-flex align-items-center btn-dropdown">
                            <RxCalendar
                              className=""
                              color="#747474"
                              size={20}
                            />
                            <Dropdown
                              className=""
                              options={dropOptions}
                              placeholder="This year"
                              onChange={handleChange}
                            />
                          </div>
                        </div>

                        <ReactApexChart
                          type="bar"
                          options={options}
                          series={series}
                        />
                      </Graph>
                      <div className="row">
                        <div className="col-md-6 col-lg-12 col-xl-6 pe-xl-2 my-2 my-xl-3">
                          <div className="bg-gray d-flex align-items-center justify-content-between py-3 px-3 px-sm-4">
                            <div className="d-flex align-items-center desk-small">
                              <img
                                src={membersIcon}
                                alt="Members"
                                className="w-25 w-sm-100"
                              />
                              <p className="mb-0 px-3">
                                NFT <br />
                                Referrals
                              </p>
                            </div>
                            <div className="d-flex flex-column text-end desk-small">
                              <small className="font-weight-300 text-green">
                                {'\u00A0'}
                                {/* +{user?.nft_referrals.new} New */}
                              </small>
                              <span className="h3 h1-sm text-blue font-weight-300 mb-0">
                                {user?.nft_referrals.current}
                              </span>
                            </div>
                          </div>
                        </div>
                        <div className="col-md-6 col-lg-12 col-xl-6 ps-xl-2 my-2 my-xl-3">
                          <div className="bg-gray d-flex align-items-center justify-content-between py-3 px-3 px-sm-4">
                            <div className="d-flex align-items-center desk-small">
                              <img
                                src={totalMembersIcon}
                                alt="Total Members"
                                className="w-25 w-sm-100"
                              />
                              <p className="mb-0 px-3">
                                Matrix <br />
                                Referrals
                              </p>
                            </div>
                            <div className="d-flex flex-column text-end desk-small">
                              <small className="text-green font-weight-300">
                                {'\u00A0'}
                                {/* +{user?.matrix_referrals.new} New */}
                              </small>
                              <span className="h3 h1-sm mb-0 text-color font-weight-300">
                                {user?.matrix_referrals.current}
                              </span>
                            </div>
                          </div>
                        </div>
                        <div className="col-12 mt-2 mt-xl-0">
                          <div className="row bg-gray align-items-center justify-content-between pad-earnings mx-0 pt-3 pt-lg-2 pt-xl-3 ps-3 pe-3 ps-sm-4 pe-sm-4 ">
                            <div className="col-md-3 d-flex align-items-center px-0 mb-4 mb-lg-0 mt-3 mt-lg-0 desk-small">
                              <img
                                src={coin}
                                alt="Coin"
                                className="img-height"
                              />
                              <p className="mb-0 px-2 px-lg-3">
                                Profit Centers
                              </p>
                            </div>
                            <div className="col-md-9 px-0 px-sm-4 ps-md-4 pe-md-2">
                              <Slider {...settings}>
                                {volumeCenters.map((volume, index) => (
                                  <div key={index.toString()} className="px-1">
                                    <div className="w-100 d-flex flex-column text-end py-2 ps-2 pe-2 border-gray volume-centers my-3 my-lg-0">
                                      <p className="mb-3 font-weight-400">
                                        Center {volume.center} Earnings
                                      </p>
                                      <span className="h3 font-weight-300 text-blue mb-0">
                                        ${volume.amount}
                                      </span>
                                    </div>
                                  </div>
                                ))}
                              </Slider>
                            </div>
                          </div>
                        </div>
                      </div>
                    </Content>
                  </div>
                  <div className="col-sm-11 col-lg-5 px-lg-0 my-2 my-lg-0">
                    <Content className="w-100 p-2">
                      <div className="bg-gray position-relative bg-transparent min-h">
                        {referrers.length > 0 && (
                          <div className="text-center btn-view text-sm-end">
                            <Link
                              to={`${process.env.PUBLIC_URL}/business/referrals`}
                              className="btn btn-default"
                            >
                              View More
                            </Link>
                          </div>
                        )}
                        <div className="row mb-3 align-items-center">
                          <div className="col-sm-6 col-lg-5">
                            <h3 className="h6 h5-xxl mt-2 mb-3">
                              My Referrals
                            </h3>
                          </div>
                          <div className="col-sm-6 col-lg-7">
                            <div className="border-gray input-members">
                              <div className="d-flex py-1 px-3 desk-small">
                                <input
                                  type="text"
                                  className="border-0 bg-transparent w-100"
                                  placeholder="Search members"
                                  onChange={handleChange}
                                />
                                <button
                                  type="submit"
                                  className="border-0 bg-transparent"
                                >
                                  <img
                                    src={search}
                                    alt="Search"
                                    className="img-search"
                                  />
                                </button>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="row px-2">
                          {referrers.length > 0 ? (
                            <>
                              {referrers.map((member, index) => (
                                <>
                                  {index < 6 && (
                                    <div
                                      key={member.id}
                                      className="col-lg-6 my-1 px-0"
                                    >
                                      <div className="d-flex align-items-center my-2">
                                        <img
                                          src={member.nft_img}
                                          alt={member.nft_img}
                                          className="avatar ms-3"
                                        />
                                        <p className="mb-0 mx-3">
                                          {index + 1}.
                                        </p>
                                        <p className="mb-0">{member.name}</p>
                                      </div>
                                      {index < 4 && (
                                        <hr className="mt-3 mb-2" />
                                      )}
                                    </div>
                                  )}
                                </>
                              ))}
                            </>
                          ) : (
                            <div className="col-12 py-5 mb-2 text-center">
                              <MdFindInPage
                                className="mt-5"
                                size={35}
                                color="#8D8D8D"
                              />
                              <p className="mb-5 mt-lg-2">
                                No records to display
                              </p>
                            </div>
                          )}
                        </div>
                      </div>
                    </Content>
                    <Content className="w-100 mt-3">
                      <div className="row">
                        <div className="col-12">
                          <div className="box-profile">
                            <div className="bg-dashboard d-flex flex-column p-4 justify-content-center position-relative">
                              <Link
                                to={`${process.env.PUBLIC_URL}/user-profile`}
                                className="position-absolute avatar-position"
                              >
                                <div className="avatar-user d-flex align-items-center text-center">
                                  <Avatar
                                    src={user.nft_img}
                                    className="my-avatar"
                                  />
                                </div>
                              </Link>
                              <div className="d-flex justify-content-between my-2 my-lg-0">
                                <span className="nft-name mb-0 text-center text-start">
                                  {name}
                                </span>
                                <span className="new-member mb-0 text-center text-start">
                                  New Member
                                  <img
                                    src={rain}
                                    alt="Rain icon"
                                    className="ms-2"
                                  />
                                </span>
                              </div>
                            </div>
                            <div className=" d-flex align-items-center justify-content-between mt-5 pt-4 px-3">
                              <p className="mb-0 w-25 height-mob ">
                                PDF <br />
                                Presentation
                              </p>
                              <Link
                                to={`${process.env.PUBLIC_URL}/business/resources`}
                                className="bg-light-gray w-65 click text-center py-3"
                              >
                                Click Here
                              </Link>
                            </div>
                            <div className=" d-flex align-items-center justify-content-between mt-4 px-3">
                              <p className="mb-0 w-25 height-mob ">
                                Main Site
                                <br />
                                Invitation Link
                              </p>

                              <Copy
                                className="w-65 padding-copy click"
                                content={`${referralCode}`}
                              />
                            </div>
                            <div className=" d-flex align-items-center justify-content-between mt-4 px-3">
                              <p className="mb-0 w-25 height-mob ">
                                Webinar <br />
                                Invitation Link
                              </p>

                              <div className="bg-light-gray w-65 click text-center py-3">
                                Coming soon
                              </div>

                              {/* <Copy
                              className="w-65 padding-copy click"
                              content="https://link.webinar/i/@nft012"
                            /> */}
                            </div>
                          </div>
                        </div>
                      </div>
                    </Content>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <ModalCongratulations showModal={show} />

          {loading && (
            <>
              <div className="loading-box">
                <div className="spinner-border text-light" role="status">
                  <span className="sr-only">Loading...</span>
                </div>
              </div>
            </>
          )}
        </>
      )}
    </Container>
  );
};

export default Dashboard;
