import styled from 'styled-components';

export const Banner = styled.div`
  padding: 0 20px;
  .container-zindex {
    z-index: 2;
  }

  .arrow {
    width: 30px;
    height: 30px;
    transition: all 0.3s ease;
  }

  .rotateActive {
    transform: rotate(-90deg);
  }

  .fw-600 {
    font-weight: 600 !important;
  }
`;

export const Video = styled.video`
  background-color: #000 !important;
  position: fixed;
  min-width: 100%;
  left: 0%;
  z-index: 1;
  height: 100vh;
  //top: 50%;
  //left: 50%;
  //min-height: 100%;
  //width: auto;

  //transform: translateX(-50%) translateY(-50%);
  transition: top 1s ease-in-out;
`;
