import React, {
  ChangeEvent,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from 'react';
import Swal from 'sweetalert2';
import { useHistory } from 'react-router-dom';

import { formatPrice } from '~/utils/format';
import { web3store } from '~/store';

import { Container } from './styles';
import WalletAvatar from '../WalletAvatar';
import ModalSwap from '../ModalSwap';
import { ModalNft } from '~/pages/Sales/ModalNftWallet';
import ModalNeedUsdpi from '~/pages/Sales/ModalNeedUsdpi';
import { ModalYouGotUsdpi } from '~/pages/Sales/ModalConfirmation';
import Loading from '~/components/Loading';

import logoWhite from '~/assets/logo/logo-p-white.svg';
import product from '~/assets/defaults/product.png';
import swalError from '~/utils/swalError';

interface IResumProps {
  qtd: number;
  multiple: number;
  activeResum?: boolean;
  disabled?: boolean;
  onChange?(e: ChangeEvent): void;
  onSubmit?(qtd: number): void;
  onClearCart?(qtd: number): void;
}

const Resum: React.FC<IResumProps> = ({
  activeResum,
  qtd,
  multiple,
  disabled,
  onChange,
  onClearCart,
}) => {
  const history = useHistory();
  const [show, setShow] = useState(false);

  const [cartAmt, setCartAmt] = web3store.useState('cartAmt');
  const [, setCartPurchase] = web3store.useState('cartPurchase');
  const [balusdpi] = web3store.useState('balusdpi');
  const [connectedMember] = web3store.useState('connectedMember');
  const [cartPurchaseTX, setCartPurchaseTX] =
    web3store.useState('cartPurchaseTX');
  const [loading, setLoading] = useState(false);
  const [showNft, setShowNft] = useState(false);
  const [showCongrats, setShowCongrat] = useState(false);
  const [purchaseComplete, setPurchaseComplete] = useState(false);
  const [chainMode, setChainMode] = web3store.useState('chainMode');

  const usdpi = useMemo(
    () => parseFloat((parseInt(balusdpi, 10) / 10 ** 18).toFixed(2)),
    [balusdpi]
  );

  const btnMessage = useMemo(() => {
    if (connectedMember) {
      return 'ACTIVATE';
    }
    if (qtd > 0) {
      return 'MINT NFT & ACTIVATE';
    }

    return 'MINT NFT ONLY';
  }, [connectedMember, qtd]);

  useEffect(() => {
    if (cartPurchaseTX) {
      setLoading(false);
      if (typeof cartPurchaseTX === 'object' && cartPurchaseTX.reason) {
        swalError({
          message: cartPurchaseTX.reason,
          textButton: 'Try Again',
        });
      } else if (
        typeof cartPurchaseTX === 'string' &&
        cartPurchaseTX.toLowerCase().includes('error')
      ) {
        swalError({
          message:
            'Your activation didn’t work, this could be for different reasons, but possibly not enough funds to cover activation and transaction fees, please try again.',
          textButton: 'Try Again',
        }).then(() => {
          setCartPurchaseTX('');
        });
      } else {
        setShowCongrat(true);
        setPurchaseComplete(true);
        setCartPurchaseTX('');
      }
    }
  }, [cartPurchaseTX, history, setCartPurchaseTX]);

  useEffect(() => {
    if (!purchaseComplete) {
      console.log('******************RESUM*******************88');
      setCartAmt(qtd * 25 + qtd * multiple);
    } else {
      setCartAmt(0);
    }
  }, [multiple, qtd, setCartAmt]);

  const handleChange = useCallback(
    (e) => {
      if (onChange) {
        onChange(e);
      }
    },
    [onChange]
  );

  const handleClearCart = useCallback(
    (e) => {
      if (onClearCart) {
        onClearCart(e);
      }
    },
    [onClearCart]
  );

  const handleClickPay = useCallback(() => {
    if (usdpi >= cartAmt) {
      setLoading(true);
      setCartPurchase(true);
    } else {
      setShowNft(false);
      swalError({
        message: 'Insufficient balance',
        textButton: 'Try Again',
      });
    }
  }, [cartAmt, setCartPurchase, usdpi]);

  useEffect(() => {
    if (connectedMember && Object.keys(connectedMember).length > 0) {
      if (connectedMember.nft.is_active) {
        setLoading(false);
        setShow(true);

        if (balusdpi > 0) {
          setChainMode('NETWORK');
        }
      }
    }
  }, [connectedMember]);

  const handleExchangeFinished = useCallback(() => {
    setLoading(true);
    setTimeout(() => {
      setLoading(false);
      setShowNft(true);
    }, 3000);
  }, []);

  return (
    <>
      <Container
        active={activeResum}
        className="mt-4 mt-lg-0 h-100 zoom-resum bg-gray p-4 text-center text-lg-start overflow-auto"
        id="resum"
      >
        <div className="d-flex justify-content-center mt-2 mt-xxl-4 mb-5">
          <WalletAvatar />
        </div>
        <div className="">
          <h4 className="mb-4">Summary</h4>
          <div className="d-flex items justify-content-between mb-4">
            <span>
              {qtd + qtd + 1} {qtd > 0 ? 'Items' : 'Item'}
            </span>
            <button type="button" onClick={handleClearCart}>
              Clear Cart
            </button>
          </div>
          <div className="d-flex flex-wrap flex-sm-nowrap align-items-center justify-content-between mb-4 ps-3 pr-2 px-lg-0">
            <div className="d-flex justify-content-between justify-content-sm-start align-items-center w-100 w-sm-auto">
              <img src={product} alt="Logo" />
              <p className="resum-width small normal-xxl mb-0 ms-3">Free NFT</p>
            </div>
            <div className="d-flex w-100 w-lg-auto justify-content-between justify-content-sm-end align-items-center">
              <div className="input-border">
                <input
                  type="number"
                  name="qtd"
                  value={1}
                  min={0}
                  max={5}
                  disabled
                />
              </div>
              <p className="h6 h5-xxl text-end text-green mb-0">$0</p>
            </div>
          </div>

          <div className="d-flex flex-wrap flex-sm-nowrap align-items-center justify-content-between mb-4 ps-3 pr-2 px-lg-0">
            <div className="d-flex justify-content-between justify-content-sm-start align-items-center w-100 w-sm-auto">
              <img src={product} alt="Logo" />
              <p className="resum-width small normal-xxl mb-0 ms-3">
                Profit <br className="d-none d-lg-block" />
                Centers
              </p>
            </div>
            <div className="d-flex w-100 w-lg-auto justify-content-between justify-content-sm-end align-items-center">
              <div className="input-border">
                <input
                  type="number"
                  name="qtd"
                  value={qtd}
                  min={0}
                  max={5}
                  onChange={handleChange}
                  disabled
                />
              </div>
              <p className="h6 h5-xxl text-end text-green mb-0">
                ${formatPrice(qtd * multiple).slice(0, -3)}
              </p>
            </div>
          </div>

          <div className="d-flex flex-wrap flex-sm-nowrap align-items-center justify-content-between mb-4 ps-3 pr-2 px-lg-0">
            <div className="d-flex justify-content-between justify-content-sm-start align-items-center w-100 w-sm-auto">
              <img src={product} alt="Logo" />
              <p className="resum-width small normal-xxl mb-0 ms-3">
                Activation
              </p>
            </div>
            <div className="d-flex w-100 w-lg-auto justify-content-between justify-content-sm-end align-items-center">
              <div className="input-border">
                <input
                  type="number"
                  name="qtd"
                  value={qtd}
                  min={0}
                  max={5}
                  onChange={handleChange}
                  disabled
                />
              </div>
              <p className="h6 h5-xxl text-end text-green mb-0">${qtd * 25}</p>
            </div>
          </div>

          <div className="d-flex align-items-center justify-content-between box-dark-gray p-3">
            <p className="mb-0">Total to pay today:</p>
            <p className="h3 text- mb-0">
              ${formatPrice(cartAmt).slice(0, -3)}
            </p>
          </div>
          <div className="ps me-3 mt-1">*Blockchain/network fees apply</div>
          {usdpi < cartAmt ? (
            <ModalNeedUsdpi
              btnText={btnMessage}
              showNft={showNft}
              onExchangeFinished={handleExchangeFinished}
            />
          ) : (
            <button
              type="button"
              onClick={handleClickPay}
              className="payment w-100 mt-4"
              disabled={disabled}
            >
              {btnMessage}
            </button>
          )}

          <span className="get-usdpi-title text-center d-block mt-5 mb-3">
            Don’t have USDPI on your wallet?
          </span>
          <div className="position-relative btn-get-usdpi d-flex">
            <ModalSwap btnText="GET USDPI HERE" className="w-100 py-3 px-4" />
          </div>
        </div>
        <ModalNft show={show} setShow={setShow} />
        <ModalYouGotUsdpi
          show={showNft}
          setShow={setShowNft}
          onClickActivated={handleClickPay}
          showCongrats={showCongrats}
          setShowCongrat={setShowCongrat}
        />
      </Container>
      <Loading
        type="dark"
        srcImg={logoWhite}
        text="WAITING FOR BLOCKCHAIN..."
        active={loading}
      />
    </>
  );
};

export default Resum;
