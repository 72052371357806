import styled from 'styled-components';
import { Modal as ModalComponent } from 'react-bootstrap';

export const Modal = styled(ModalComponent)`
  .modal-content {
    background-color: #030303;
    border-radius: 40px;
    position: relative;

    h2 {
      font-family: 'Poppins';
      font-weight: 700;
      font-size: 32px;
      color: #cdcecf;
      z-index: 1;
    }

    img {
      width: 750px;
      margin: -4rem;
    }

    .modal-close {
      color: #e9e9e9;
      position: absolute;
      right: 20px;
      top: 20px;
    }

    .my-n5 {
      margin: -3rem 0;
    }

    .modal-footer {
      z-index: 1;

      p {
        font-family: 'Poppins';
        color: #c4c4c4;
        font-size: 15px;
        line-height: 18px;
      }

      button {
        width: 768.41px;
        max-width: 100%;
        background: #0081f9;
        border-radius: 20px;
        font-size: 24px;
        transition-duration: 0.3s;
        :hover {
          opacity: 0.9;
        }
      }
    }
  }

  @media screen and (max-width: 1799px) {
    .modal-dialog {
      zoom: 0.7;
    }
  }

  @media screen and (max-width: 991px) {
    h2 {
      font-size: 24px;
    }
    .modal-content img {
      margin: -1rem 0 -4rem 0;
    }
  }
`;
