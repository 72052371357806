import React, { useCallback, useState, useEffect } from 'react';
import { web3store } from '../../store';

import { Modal } from './styles';

import aiverifyGif from '~/assets/animations/aiverify.gif';

const ModalWrongNetwork: React.FC = () => {
  const [show, setShow] = useState(false);
  const [wrongChain, setWrongChain] = web3store.useState('wrongChain');
  const [chainMode, setChainMode] = web3store.useState('chainMode');
  const [skipModal, setSkipModal] = web3store.useState('skipModal');
  const [chainDefined, setChainDefined] = useState(false);

  let nativeCurrency: any;
  let network: any;
  let buttonTxt: any;

  if (chainMode === 'PAY') {
    nativeCurrency = {
      name: process.env.REACT_APP_PAY_CURRENCY_NAME as string,
      symbol: process.env.REACT_APP_PAY_CURRENCY_SYMBOL as string,
      decimals: parseInt(
        process.env.REACT_APP_PAY_CURRENCY_DECIMALS as string,
        10
      ),
    };
    network = {
      chainId: process.env.REACT_APP_PAY_CHAIN_ID as string,
      chainName: process.env.REACT_APP_PAY_CHAIN_NAME as string,
      rpcUrls: [process.env.REACT_APP_PAY_RPC_URLS as string],
      blockExplorerUrls: [
        process.env.REACT_APP_PAY_BLOCK_EXPLORER_URLS as string,
      ],
      nativeCurrency,
    };
    buttonTxt = process.env.REACT_APP_PAY_BUTTON_TEXT;
  } else {
    nativeCurrency = {
      name: process.env.REACT_APP_NATIVE_CURRENCY_NAME as string,
      symbol: process.env.REACT_APP_NATIVE_CURRENCY_SYMBOL as string,
      decimals: parseInt(
        process.env.REACT_APP_NATIVE_CURRENCY_DECIMALS as string,
        10
      ),
    };
    network = {
      chainId: process.env.REACT_APP_NETWORK_CHAIN_ID as string,
      chainName: process.env.REACT_APP_NETWORK_CHAIN_NAME as string,
      rpcUrls: [process.env.REACT_APP_NETWORK_RPC_URLS as string],
      blockExplorerUrls: [
        process.env.REACT_APP_NETWORK_BLOCK_EXPLORER_URLS as string,
      ],
      nativeCurrency,
    };
    buttonTxt = process.env.REACT_APP_NETWORK_BUTTON_TEXT;
  }

  let headerText = '';
  if (skipModal) {
    headerText = 'Switching Networks';
  } else {
    headerText = 'Uh-oh! Your wallet is on the wrong network...';
  }

  let displayText = '';
  if (skipModal) {
    displayText = 'Please confirm the switch in your wallet.';
  } else if (chainMode === 'PAY') {
    displayText =
      'The operation you are attempting requires access to the Ethereum Blockchain';
  } else {
    displayText = `PROFITi runs on the Binance Smart Chain, if you already have it
      installed on your Metamask, please switch it to continue. If you
      don't have it installed yet, simply click the button below to
      install&nbsp;it.`;
  }

  const provider = (window as any).ethereum;

  const handleClose = useCallback(() => {
    setShow(false);
  }, []);

  const handleClick = () => {
    console.log('+++++++++++ network.chainId', network.chainId);

    provider
      .request({
        method: 'wallet_switchEthereumChain',
        params: [{ chainId: network.chainId }],
      })
      .then(() => {
        console.log('Network switch complete');
      })
      .catch((neterror: any) => {
        console.error('Failed to switch network:', neterror);
        provider
          .request({
            method: 'wallet_addEthereumChain',
            params: [network],
          })
          .then(() => {
            console.log('New network added successfully!');
          })
          .catch((error: any) => {
            console.error('Failed to add new network:', error);
          });
      });
  };

  useEffect(() => {
    setShow(wrongChain);
    if (wrongChain && skipModal) {
      handleClick();
    }
  }, [wrongChain]);

  return (
    <Modal size="xl" show={show} className="modal-wrong-network">
      <Modal.Header className="justify-content-center border-0 pb-0 pb-lg-4 pt-5">
        <h2 className="mt-4 mt-lg-0 fw-bold text-center w-100">{headerText}</h2>
      </Modal.Header>
      <Modal.Body className="px-4 px-sm-5 mb-n5">
        <div className="my-n4 my-lg-n5 d-flex align-items-center justify-content-center overflow-hidden">
          <img src={aiverifyGif} alt="aiverify" />
        </div>
      </Modal.Body>
      <Modal.Footer className="mt-n5 d-flex justify-content-center border-0 px-4 pb-5 flex-column">
        <div className="w-75">
          <p className="text-center mb-4 px-xl-5">{displayText}</p>
          {!skipModal && (
            <button
              type="button"
              className="d-flex justify-content-center align-items-center w-100 border-0 text-white py-3 fw-bold"
              onClick={handleClick}
            >
              <span className="">{buttonTxt}</span>
            </button>
          )}
        </div>
      </Modal.Footer>
    </Modal>
  );
};

export default ModalWrongNetwork;
