import styled from 'styled-components';

export const Container = styled.div`
  background: rgba(152, 148, 148, 0.1);
  box-shadow: 3px 4px 32px rgba(0, 0, 0, 0.54);
  backdrop-filter: blur(15px);
  border-radius: 30px;

  .more {
    font-weight: 600;
    font-size: 22px;
    line-height: 27px;
    color: #ffffff;
  }

  .bg-btn {
    background: rgba(152, 148, 148, 0.1);
    box-shadow: 3px 4px 32px rgba(0, 0, 0, 0.54);
    backdrop-filter: blur(15px);
    border-radius: 20px;
  }

  .prize {
    font-weight: 500;
    font-size: 12px;
    line-height: 35px;
    color: #ffffff;
  }

  .prize-value {
    font-weight: 600;
    font-size: 24px;
    line-height: 29px;
    color: #bbbbbb;
  }
`;
