import React, { useEffect } from 'react';
import { useLocation } from 'react-router-dom';

import Header from '~/components/Header';
import Footer from '~/components/Footer';

const AuthLayout: React.FC = ({ children }) => {
  const location = useLocation();

  const referrer = location.pathname.match('/i/');
  const tryPage = location.pathname.match('/try/i/');

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [location]);

  return (
    <>
      {location.pathname === '/' || (!tryPage && referrer) ? <Header /> : ''}
      {children}
      <Footer />
    </>
  );
};

export default AuthLayout;
