import styled from 'styled-components';

export const Container = styled.div`
  background: rgba(152, 148, 148, 0.1);
  box-shadow: 3px 4px 32px rgba(0, 0, 0, 0.54);
  backdrop-filter: blur(15px);
  border-radius: 30px;

  .stats {
    font-weight: 600;
    font-size: 22px;
    line-height: 27px;
    color: #ffffff;
  }

  .bg-win {
    background: rgba(152, 148, 148, 0.1);
    backdrop-filter: blur(15px);
    border-radius: 20px;
  }

  .value-profiti {
    background: rgba(0, 0, 0, 0.46);
    box-shadow: 3px 4px 32px rgba(0, 0, 0, 0.54);

    border-radius: 15px;
  }

  .value-profiti img {
    height: 28px;
    width: 28px;
  }

  .value-profiti span {
    color: #fff;
    font-weight: 600;
  }

  .text-gradient {
    font-weight: 600;
    font-size: 32px;
    line-height: 39px;
    background: linear-gradient(
      239.23deg,
      #9df3ff 7.84%,
      #bcffe5 28.62%,
      #deefd1 38.94%,
      #ffe0be 49.96%,
      #fbc2d3 64.82%,
      #fabcd6 67.63%,
      #f281ff 91.88%
    );
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    background-clip: text;
    text-fill-color: transparent;
  }

  .text-gray {
    color: #7b7b7b;
    font-weight: 600;
    font-size: 32px;
    line-height: 39px;
  }
`;

export const Graph = styled.div`
  background: rgba(152, 148, 148, 0.1);
  box-shadow: 3px 4px 32px rgba(0, 0, 0, 0.54);

  border-radius: 20px;
  padding: 20px;

  @media screen and (max-width: 991px) {
    padding: 10px 0;
  }
`;
