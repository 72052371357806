import React, { useCallback, useState } from 'react';

import { Container } from './styles';
import cubeImg from '~/assets/icons/cube.svg';
import cubeSmall from '~/assets/defaults/cube-small.png';
import arrow from '~/assets/icons/arrow-bottom.svg';

const MoreCubes: React.FC = () => {
  const [activeCubes, setActiveCubes] = useState(false);
  const handleActiveCubes = useCallback(() => {
    setActiveCubes(!activeCubes);
  }, [activeCubes]);
  return (
    <Container className="pt-4">
      <div className="d-flex justify-content-between align-items-center px-4 pb-4">
        <span className="more d-flex align-items-center">
          More Cubes <img src={cubeImg} alt="Cube" className="ms-3" />
        </span>
        <button
          type="button"
          onClick={handleActiveCubes}
          className={`${
            activeCubes && 'rotateActive'
          } bg-transparent border-0 arrow`}
        >
          <img src={arrow} alt="Arrow" />
        </button>
      </div>
      {activeCubes && (
        <div className="d-flex flex-wrap px-3 pb-2">
          <div className="w-50 pe-2 py-2">
            <div className="d-flex w-100 border-0 bg-btn p-1">
              <img src={cubeSmall} alt="Cube Small" />
              <span className="d-block prize ps-3 text-start">
                Payout
                <br />
                <span className="d-block prize-value">$50</span>
              </span>
            </div>
          </div>
          <div className="w-50 ps-2 py-2">
            <div className="d-flex w-100 border-0 bg-btn p-1">
              <img src={cubeSmall} alt="Cube Small" />
              <span className="d-block prize ps-3 text-start">
                Payout
                <br />
                <span className="d-block prize-value">$150</span>
              </span>
            </div>
          </div>
          <div className="w-50 pe-2 py-2">
            <div className="d-flex w-100 border-0 bg-btn p-1">
              <img src={cubeSmall} alt="Cube Small" />
              <span className="d-block prize ps-3 text-start">
                Payout
                <br />
                <span className="d-block prize-value">$500</span>
              </span>
            </div>
          </div>
          <div className="w-50 ps-2 py-2">
            <div className="d-flex w-100 border-0 bg-btn p-1">
              <img src={cubeSmall} alt="Cube Small" />
              <span className="d-block prize ps-3 text-start">
                Payout
                <br />
                <span className="d-block prize-value">$5,000</span>
              </span>
            </div>
          </div>
        </div>
      )}
    </Container>
  );
};

export default MoreCubes;
