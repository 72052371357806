import styled from 'styled-components';

export const Container = styled.div`
  background: rgba(152, 148, 148, 0.1);
  box-shadow: 3px 4px 32px rgba(0, 0, 0, 0.54);
  backdrop-filter: blur(15px);
  border-radius: 30px;

  .btn-avatar-wallet {
    background: transparent !important;
    border-radius: 30px;
  }

  .title {
    font-weight: 600;
    font-size: 30px;
    line-height: 36px;
    color: #ffffff;
  }

  .bg-values span {
    min-width: 100px;
  }

  .chat button {
    cursor: pointer;
    border: none;
    padding: 0 0 3px 0;
    background: transparent;
    background-origin: border-box !important;

    border-image-slice: 1 !important;

    transition-duration: 0.3s;
    font-weight: 600;
    font-size: 18px;
    line-height: 22px;
    color: #7d7777;
    span {
      padding: 5px 20px;
      display: block;
      background: #100f0f;
      padding-bottom: 3px;
    }
    :hover {
      background: linear-gradient(
        270deg,
        rgba(255, 255, 255, 0) 0%,
        rgba(255, 255, 255, 0.557292) 54.44%,
        rgba(255, 255, 255, 0) 87.84%
      );
      color: #fff;
    }
  }

  .chat-active {
    background: linear-gradient(
      270deg,
      rgba(255, 255, 255, 0) 0%,
      rgba(255, 255, 255, 0.557292) 54.44%,
      rgba(255, 255, 255, 0) 87.84%
    ) !important;
    color: #fff !important;
  }

  .messages img {
    height: 46px;
    width: 46px;
  }

  .messages .no-avatar {
    min-height: 46px;
    min-width: 46px;
  }

  .hour {
    color: #bfbfbf;
    font-size: 12px;
  }

  .bg-participants {
    background: rgba(83, 83, 83, 0.45);
    backdrop-filter: blur(2px);
    border-radius: 0px 6px 6px 6px;
  }

  .bg-you {
    background: rgba(24, 25, 26, 0.85);
    backdrop-filter: blur(2px);
    border-radius: 6px 0px 6px 6px;
  }

  .vh-61 {
    height: 61vh;
    transition: all 0.3s ease;
  }
  .vh-26 {
    height: 26vh;
    transition: all 0.3s ease;
  }

  .bg-input {
    background: rgba(24, 25, 26, 0.85);
    box-shadow: 0px 14px 28px rgba(0, 0, 0, 0.25);
    border-radius: 12px;
  }

  .bg-input input::placeholder {
    color: #fff !important;
  }

  .bg-input button {
    background: rgba(255, 255, 255, 0.8);
    border-radius: 8px;
  }

  aside.EmojiPickerReact.epr-main {
    transition: all 0.3s ease;
    border-style: none;
    border-width: none;
    background: rgba(24, 25, 26, 0.85);
    box-shadow: 0px 14px 28px rgb(0 0 0 / 25%);
    border-radius: 12px;
  }

  .EmojiPickerReact li.epr-emoji-category > .epr-emoji-category-label {
    background-color: rgba(24, 25, 26, 0.85);
  }
`;
