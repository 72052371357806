import React from 'react';

import { Banner, Video } from './styles';

import CurrentPayout from './CurrentPayout';
import WinStats from './WinStats';
import MoreCubes from './MoreCubes';
import LiveChat from './LiveChat';

const Game: React.FC = () => {
  // https://s3.us-east-1.amazonaws.com/livplus.io/videos/cube_2_final.mp4
  return (
    <Banner>
      <Video autoPlay loop muted className="bg_video">
        <source
          src="https://s3.us-east-1.amazonaws.com/livplus.io/videos/cube_2_final.mp4"
          type="video/mp4"
        />
      </Video>
      <div className="container-fluid position-relative container-zindex pt-4">
        <div className="row">
          <div className="col-lg-3">
            <CurrentPayout />
          </div>
          <div className="col-lg-3 pe-lg-4">
            <WinStats />
          </div>
          <div className="col-lg-3 ps-lg-4">
            <MoreCubes />
          </div>

          <div className="col-lg-3">
            <LiveChat />
          </div>
        </div>
      </div>
    </Banner>
  );
};

export default Game;
