import styled, { css } from 'styled-components';
import { Modal as ModalComponent } from 'react-bootstrap';

interface IP {
  status: string;
}

export const Container = styled.div`
  .box {
    border: 1.5px solid #1f2020;
    border-radius: 20px;
    min-height: 240px;

    p {
      color: #8c8c8c;
    }

    h2 {
      color: #dadada;
    }

    p.small {
      font-size: 14px;
      color: #5d5b5b;
    }

    .infos {
      img:first-child {
        margin-right: 12px;
      }

      small {
        width: calc(100% - 26px);
      }
    }

    .errors {
      small {
        color: #ff1a50;
        width: unset;
      }

      img:nth-child(3) {
        margin-left: 12px;
      }
    }

    .btn-fund-wallet {
      background: #00f9ab;
      border-radius: 16px;
      width: 100%;
      height: 50px;
      margin-top: 20px;
      color: #000;
      font-weight: 600;
      transition-duration: 0.3s;
      :hover {
        opacity: 0.8;
      }
    }
  }

  .box.last {
    background: #0d0d0e;
    border: 1.5px solid #1f2020;
    border-radius: 20px;
  }

  .bg-gray {
    background: #1d2023;
    border-radius: 20px;

    .border-right {
      border-color: #393c3d !important;
    }

    h2 {
      color: #dadada;
    }

    .search {
      border-radius: 20px;
      padding: 5px 10px;
    }

    .search input {
      background-color: transparent;
      border: none;
    }

    .exportable {
      border: solid 1px #333;
      border-radius: 20px;
    }

    .react-datepicker__input-container {
      padding: 5px 10px;
      border: solid 1px #333;
      border-radius: 20px;
    }

    .react-datepicker__input-container input {
      background-color: transparent;
      border: none;
    }

    .input {
      border: 1.5px solid #393c3d;
      box-sizing: border-box;
      border-radius: 15px;
      height: 40px;

      input {
        color: #efefef !important;
      }
    }

    button.btn-gradient {
      border-radius: 15px;
    }
  }

  .table {
    .rdt_TableHeadRow,
    .rdt_TableRow {
      > div:nth-child(2),
      > div:nth-child(4) {
        max-width: 175px;
        width: 175px;
      }
    }
  }

  .rdt_TableRow {
    border-bottom: 1px solid #383939;
  }

  .rdt_Table {
    min-width: 870px;
  }

  .contact {
    cursor: pointer;

    input {
      color: #8c8c8c;
      font-size: 13px;
      background-color: transparent;
      border: none;
    }
  }
`;

export const Welcome = styled.div`
  h1 {
    color: #dadada;
  }

  p {
    color: #969696;
  }

  .btn-month {
    background: #242526;
    border: 1px solid rgba(225, 225, 225, 0.2);

    span {
      color: #8c8c8c;
    }
  }
`;

export const NameTable = styled.div`
  img {
    width: 50px;
    height: 50px;
    border-radius: 15px;
  }
`;

export const P = styled.div<IP>`
  font-size: 18px;
  font-weight: 500;
  text-transform: uppercase;
  ${(props) => {
    switch (props.status.toLowerCase()) {
      case 'paid':
        return css`
          color: #00f9ab;
        `;
        break;
      case 'late':
        return css`
          color: #ff1a50;
        `;
        break;

      default:
        return css`
          color: #8d8d8d;
        `;
        break;
    }
  }}
`;

export const Modal = styled(ModalComponent)`
  .modal-content {
    background: #171719 !important;
    border-radius: 40px;
    position: relative;

    h2 {
      font-family: 'Poppins';
      font-style: normal;
      font-weight: 700;
      font-size: 48px;
      color: #dadada;
    }

    .modal-title,
    label span {
      color: #dadada;
    }

    .input {
      border: 1px solid #4f4f4f;
      border-radius: 20px;
      height: 72px !important;

      input::placeholder {
        color: #5d5b5b;
        font-weight: 300;
        font-size: 14px;
      }
    }

    .modal-close {
      color: #e9e9e9;
      position: absolute;
      right: 20px;
      top: 20px;
    }

    .claim {
      background: #a3c0e1;
      border-radius: 10px;
      font-weight: 700;
      font-size: 10px;
      color: #0927c6;
      transition-duration: 0.3s;

      :hover {
        opacity: 0.8;
      }

      :disabled {
        opacity: 0.7;
      }
    }

    .texts p:nth-child(1),
    .texts p:nth-child(4) {
      font-family: 'Poppins';
      font-weight: 400;
      font-size: 22px;
      line-height: 118%;
      color: #a6a2a2;
    }

    .texts .terms {
      background: #1d1d1f;
      border-radius: 12px;
      font-weight: 300;
      font-size: 16px;
      padding: 10px;
      span {
        font-weight: 300;
        font-size: 16px;
        line-height: 32px;
        color: #7d7777;
      }
    }

    .color-span {
      color: #a6a2a2 !important;
      font-weight: 500 !important;
    }

    .btn-confirm {
      background: #696ffd;
      border-radius: 20px;
      border: none;
      color: #fff;
      font-weight: 700;
      font-size: 24px;
      padding: 20px;
      transition-duration: 0.3s;
      :hover {
        opacity: 0.8;
      }
    }

    .btn-done {
      background: #2b3247;
      border-radius: 20px;
      border: none;
      color: #fff;
      font-weight: 700;
      font-size: 24px;
      padding: 20px;
      transition-duration: 0.3s;
      :hover {
        opacity: 0.8;
      }
    }

    .btn-cancel {
      background: #0f0f0f;
      border-radius: 20px;
      border: none;
      height: 75px;
      color: #fff;
      font-weight: 700;
      font-size: 24px;
      padding: 20px;
      transition-duration: 0.3s;
      :hover {
        opacity: 0.8;
      }
    }

    .btn-fund-wallet {
      background: #00f9ab;
      border-radius: 20px;
      border: none;
      height: 75px;
      color: #000;
      font-weight: 700;
      font-size: 24px;
      padding: 20px 60px;
      transition-duration: 0.3s;

      :hover {
        opacity: 0.8;
      }
    }
  }

  @media screen and (max-width: 1799px) {
    .modal-dialog {
      zoom: 0.7;
    }
  }

  @media screen and (max-width: 991px) {
    h2 {
      font-size: 24px;
    }
    .modal-content img {
      margin: -1rem 0 -4rem 0;
    }
  }
`;

export const ModalFinish = styled(ModalComponent)`
  .modal-content {
    background: #171719 !important;
    border-radius: 40px;
    position: relative;

    h2 {
      font-family: 'Poppins';
      font-style: normal;
      font-weight: 700;
      font-size: 48px;
      color: #dadada;
    }

    img {
      width: 750px;
      margin: -4rem;
    }

    .modal-close {
      color: #e9e9e9;
      position: absolute;
      right: 20px;
      top: 20px;
    }

    .texts p:nth-child(1) {
      font-family: 'Poppins';
      font-weight: 400;
      font-size: 24px;
      line-height: 118%;
      color: #a6a2a2;
    }

    .texts p:nth-child(2) {
      font-family: 'Poppins';
      font-weight: 400;
      font-size: 20px;
      color: #7d7777;
    }

    .texts div:nth-child(3) {
      border: 1px solid #4f4f4f;
      border-radius: 20px;
      font-weight: 300;
      font-size: 14px;
      align-items: center;
      text-align: center;
      color: #8767ed;
      padding: 23px;
    }

    .texts p:nth-child(4) {
      font-family: 'Poppins';
      font-weight: 400;
      font-size: 20px;
      line-height: 118%;
      text-align: center;
      color: #a6a2a2;
    }

    .btn-add-usdpi {
      background: #01c2c5;
      border-radius: 20px;
      border: none;
      color: #fff;
      font-weight: 700;
      font-size: 24px;
      padding: 20px;
      transition-duration: 0.3s;
      :hover {
        opacity: 0.8;
      }
    }

    .btn-add-prfti {
      background: #6d50d0;
      border-radius: 20px;
      border: none;
      color: #fff;
      font-weight: 700;
      font-size: 24px;
      padding: 20px;
      transition-duration: 0.3s;
      :hover {
        opacity: 0.8;
      }
    }
  }

  @media screen and (max-width: 1799px) {
    .modal-dialog {
      zoom: 0.7;
    }
  }

  @media screen and (max-width: 991px) {
    h2 {
      font-size: 24px;
    }
    .modal-content img {
      margin: -1rem 0 -4rem 0;
    }
  }
`;
