import styled from 'styled-components';

interface IBtnAvatar {
  isFunnel: boolean;
}

interface IAvatar {
  src: string;
}

export const Container = styled.div`
  img {
    width: 42px;
    height: 42px;
  }

  hr {
    background: #383939;
    height: 1px;
    border: none;
  }

  .disconnect-tooltip {
    position: relative;
  }

  .disconnect-tooltip:after {
    background-color: #212122;
    color: #8c8c8c;
    border: 1px solid #262626;
    border-radius: 15px;
    box-sizing: border-box;
    content: attr(data-tooltip);
    display: none;
    padding: 15px;
    font-size: 10px;
    position: absolute;
    right: 0px;
    bottom: 42px;
    width: 120px;
    z-index: 10000;
    text-align: justify;
  }

  .disconnect-tooltip:hover:after {
    display: block;
  }

  .reference span:nth-child(1) {
    font-weight: 300;
    font-size: 14px;
    color: #7d7777;
    span {
      font-weight: 300;
      font-size: 16px;
      color: #ffffff;
    }
  }

  .avatar-token {
    background: transparent;
  }

  .wallet-num {
    font-weight: 600;
    font-size: 18px;
    color: #ffffff;
  }

  .avatar {
    height: 49px;
    width: 49px;
    padding: 3px;
    background-image: linear-gradient(180deg, #242526, #242526),
      linear-gradient(
        239.23deg,
        #9df3ff 7.84%,
        #bcffe5 28.62%,
        #deefd1 38.94%,
        #ffe0be 49.96%,
        #fbc2d3 64.82%,
        #fabcd6 67.63%,
        #f281ff 91.88%
      ) !important;
    background-origin: border-box !important;
    background-clip: content-box, border-box !important;
    border-image-slice: 1 !important;
    border-radius: 15px;
    -webkit-transition-duration: 0.3s;
    transition-duration: 0.3s;
    cursor: unset;
  }

  .bg-values {
    background-color: #1d1d1f;
    border-radius: 24px 10px 10px 24px;
  }

  .bg-values span {
    min-width: 130px;
    font-weight: 600;
  }

  .disconnect,
  .token {
    top: 60px;
    right: 0;
    background: #18191a;
    border: 1px solid #373737;
    box-shadow: 4px 15px 40px rgba(0, 0, 0, 0.25);
    border-radius: 25px;
    width: 350px;
    z-index: 1055;
  }

  .token .title {
    background: #1d1e1f;
    border-radius: 16px;
    font-weight: 300;
    font-size: 15px;
  }

  .token .check {
    width: 20px;
    height: 14px;
  }

  .token .coin {
    background: transparent;
    border-radius: 14px;
    :hover {
      background: #1c1c1c;
    }
  }

  .token span {
    font-family: 'Poppins';
    font-weight: 500;
    font-size: 20px;
    color: #7d7777;
  }

  .btn-back span {
    font-family: 'Inter';
    font-weight: 300;
    font-size: 16px;
    color: #7d7777;
  }

  .text-balance {
    font-weight: 400;
    font-size: 16px;
    color: #7d7777;
  }

  .balance {
    font-weight: 400;
    font-size: 40px;
    color: #dadada;
  }

  .btn-logout {
    background: rgba(152, 148, 148, 0.1);
    border-radius: 16px;
    transition: all 0.3s ease;
    :hover {
      opacity: 0.9;
    }
  }

  .btn-view {
    background: #5a57ee;
    //background: #666;
    border-radius: 15px;
    border: none;
    font-weight: 600;
    font-size: 22px;
    color: #f0ebeb;
    transition: all 0.3s ease;
    :hover {
      opacity: 0.9;
      //cursor: not-allowed;
    }
  }

  .btn-buy {
    background: #2b3247;
    border-radius: 15px;
    border: none;
    font-weight: 600;
    font-size: 22px;
    color: #f0ebeb;
    transition: all 0.3s ease;
    :hover {
      opacity: 0.9;
    }
  }

  .btns-color {
    transition: all 0.3s ease;
    :hover {
      opacity: 0.9;
    }
  }

  .btns-color:disabled {
    :hover {
      cursor: not-allowed;
    }
  }

  .btns-color span {
    font-weight: 400;
    font-size: 16px;
    color: #7d7777;
  }

  .btns-color:disabled {
    span {
      color: #444;
    }
  }

  .btns-color span svg {
    rotate: -90deg;
  }

  .text-disconnect {
    font-weight: 600;
    font-size: 36px;
    color: #dadada;
  }

  .btn-yes-busd,
  .btn-yes-usdt,
  .btn-yes-usdc,
  .btn-yes-gusd,
  .btn-yes-dai {
    //background: #ff1a50;
    border-radius: 15px;
    font-weight: 600;
    font-size: 22px;
    //color: #f0ebeb;
    border: none;
    transition: all 0.3s ease;
    :hover {
      opacity: 0.9;
    }
  }

  .btn-yes-busd {
    background: #ffb422;
    color: #000;
  }
  .btn-yes-usdt {
    background: #26a17b;
    color: #f0ebeb;
  }
  .btn-yes-usdc {
    background: #2775ca;
    color: #f0ebeb;
  }
  .btn-yes-gusd {
    background: #00dcfa;
    color: #000;
  }
  .btn-yes-dai {
    background: #f5ac37;
    color: #000;
  }

  .btn-yes {
    background: #ff1a50;
    border-radius: 15px;
    font-weight: 600;
    font-size: 22px;
    color: #f0ebeb;
    border: none;
    transition: all 0.3s ease;
    :hover {
      opacity: 0.9;
    }
  }

  .btn-no {
    background: #2b3247;
    border-radius: 15px;
    font-weight: 600;
    font-size: 22px;
    color: #f0ebeb;
    border: none;
    transition: all 0.3s ease;
    :hover {
      opacity: 0.9;
    }
  }

  @media screen and (max-width: 1799px) {
    .zoom {
      zoom: 0.8;
    }
  }

  @media screen and (max-width: 1399px) {
    .zoom {
      zoom: 0.7;
    }
  }
`;

export const BtnAvatar = styled.button<IBtnAvatar>`
  background: ${(props) =>
    props.isFunnel ? 'transparent' : 'rgb(29, 29, 31)'};
  border: 1px solid ${(props) => (props.isFunnel ? 'transparent' : '#1d2023')};
  border-radius: 30px 10px 10px 30px;
  padding: 2px;
  transition: all 0.2s ease;

  :hover {
    border-color: #484848;
  }
`;

export const Avatar = styled.div<IAvatar>`
  width: 100%;
  height: 100%;
  background-image: url(${(props) => props.src});
  background-size: cover;
  background-position: top;
`;
