import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { format } from 'date-fns';
import Lottie from 'react-lottie';

import { HiArrowNarrowRight } from 'react-icons/hi';
import { Form } from '@unform/web';
import api from '~/services/api';
import { useAuth } from '~/hooks/Auth';
import { web3store } from '~/store';

import { Container, Welcome, P, Modal, ModalFinish } from './styles';
import Table from '~/components/Table';
import WalletRow from '~/components/WalletRow';
import Loading from '~/components/Loading';

import logoWhite from '~/assets/logo/logo-p-white.svg';
import checkmark from '~/assets/icons/checkmark.svg';
import xCircle from '~/assets/icons/x-circle.svg';
import info from '~/assets/icons/info.svg';
import stakingTerms from '~/assets/animations/staking-terms-details.json';
import earningsDone from '~/assets/animations/earnings-done.json';
import Input from '~/components/Input';
import { formatPrice } from '~/utils/format';

interface IReferralResponse {
  referrals: {
    total_count: number;
    referrals: {
      id: number;
      name: string;
      nft_img: string;
      rank: string;
      status: string;
      date_registered: number;
    }[];
  };
}

interface IBilling {
  id: number;
  invoice: string;
  amount: string;
  date: string;
  status: string;
}

interface ISubscription {
  annual: string;
  annual_due: string;
  monthly: string;
  monthly_due: string;
  balance: string;
}

const Referrers: React.FC = () => {
  const { user } = useAuth();
  const [loading, setLoading] = useState(true);
  const [name, setName] = useState('');
  const [startDate, setStartDate] = useState(
    new Date(new Date().getFullYear(), 0, 1)
  );
  const [endDate, setEndDate] = useState(
    new Date(new Date().getFullYear(), 11, 31)
  );
  const [subscription, setSubscription] = useState({} as ISubscription);
  const [billings, setBillings] = useState<IBilling[]>([]);
  const [billingSelected, setBillingSelected] = useState({} as IBilling);
  const [show, setShow] = useState(false);
  const [showFundAccountWallet, setShowFundAccountWallet] = useState(false);
  const [showFinish, setShowFinish] = useState(false);
  const explorerURL = process.env.REACT_APP_NETWORK_BLOCK_EXPLORER_URLS;
  const [withdrawalResult, setWithdrawalResult] =
    web3store.useState('withdrawalResult');
  const [connectedMember, setConnectedMember] =
    web3store.useState('connectedMember');
  const [subBalance, setSubBalance] = useState(0);
  const [amtNeeded, setAmtNeeded] = useState(0);
  const [enoughText, setEnoughText] = useState('');

  // useEffect(() => {
  //   setHasEnough
  // }, [subBalance, amtNeeded]);

  useEffect(() => {
    console.log('+++++ USER +++++');
    console.log(user);
    api
      .get(`v1/member/subscription/${user?.id}`)
      .then(async (responseSubscription) => {
        console.log(responseSubscription.data.subscription);
        setAmtNeeded(
          parseInt(responseSubscription.data.subscription.monthly, 10) +
            10 * 10 ** 18
        );
        setSubBalance(
          parseInt(responseSubscription.data.subscription.balance, 10)
        );
        setSubscription({
          annual: formatPrice(
            parseFloat(
              (
                parseInt(responseSubscription.data.subscription.annual, 10) /
                10 ** 18
              ).toFixed(2)
            )
          ),
          annual_due: responseSubscription.data.subscription.annual_due,
          monthly: formatPrice(
            parseFloat(
              (
                parseInt(responseSubscription.data.subscription.monthly, 10) /
                10 ** 18
              ).toFixed(2)
            )
          ),
          monthly_due: responseSubscription.data.subscription.monthly_due,
          balance: formatPrice(
            parseFloat(
              (
                parseInt(responseSubscription.data.subscription.balance, 10) /
                10 ** 18
              ).toFixed(2)
            )
          ),
        });
      });
    api
      .get('v1/billing/history', {
        params: {
          nftId: connectedMember.memberId,
        },
      })
      .then(function (response) {
        console.log(response);
        // setNftlist(response.data.nft_list);
        setBillings(response.data.history);
      })
      .catch(function (error) {
        console.log('================= ERROR ===================');
        console.log(error);
      });

    // setBillings([
    //   {
    //     id: 1,
    //     invoice: 'Yearly - Affiliate Activation',
    //     amount: '$25',
    //     date: '01-JAN-2023',
    //     status: 'paid',
    //   },
    //   {
    //     id: 2,
    //     invoice: 'Monthly Subscription',
    //     amount: '$50',
    //     date: '01-JAN-2023',
    //     status: 'paid',
    //   },
    //   {
    //     id: 3,
    //     invoice: 'Monthly Subscription',
    //     amount: '$50',
    //     date: '01-FEB-2023',
    //     status: 'peding',
    //   },
    //   {
    //     id: 4,
    //     invoice: 'Monthly Subscription',
    //     amount: '$50',
    //     date: '01-MAR-2023',
    //     status: 'late',
    //   },
    // ]);
    setLoading(false);
  }, [user]);

  const handleClose = useCallback(() => {
    setShow(false);
    setShowFundAccountWallet(false);
    setShowFinish(false);
  }, []);

  const handleShowTx = useCallback((billing: IBilling) => {
    setBillingSelected(billing);
  }, []);

  const columns = useMemo(
    () => [
      {
        name: 'Invoice',
        selector: 'invoice',
        sortable: true,
      },
      {
        name: 'Amount',
        selector: 'amount',
        sortable: true,
      },
      {
        name: 'Date',
        selector: 'date',
        sortable: true,
      },
      {
        name: 'Status',
        selector: 'status',
        sortable: true,
        cell: (row: IBilling) => (
          <P className="mb-0" status={row.status}>
            {row.status}
          </P>
        ),
      },
      {
        name: '',
        selector: 'id',
        sortable: true,
        cell: (row: IBilling) => (
          <button
            type="button"
            className="border-0 bg-transparent"
            disabled={row.status !== 'paid'}
            onClick={() => handleShowTx(row)}
          >
            {row.status === 'paid' ? 'View TX' : '-'}
          </button>
        ),
      },
    ],
    []
  );

  const handleSearch = useCallback((value) => {
    setName(value);
  }, []);

  const handleChangeStartDate = useCallback((value) => {
    setStartDate(value);
  }, []);

  const handleChangeLastDate = useCallback((value) => {
    setEndDate(value);
  }, []);

  const handleClickFundWallet = useCallback(() => {
    setShowFundAccountWallet(true);
  }, []);

  const handleSubmit = useCallback(() => {
    setShowFinish(true);
  }, []);

  return (
    <Container>
      <div className="container-fluid container-xxl">
        <div className="row justify-content-center">
          <div className="col-sm-11 col-lg-12 mb-2 mt-4">
            <Welcome>
              <div className="container-fluid">
                <div className="row align-items-center mb-4">
                  <div className="col-lg-6 px-0">
                    <h1 className="h3 h2-lg font-weight-bold mb-lg-0">
                      Subscriptions
                    </h1>
                    <p>Manage your plan and billing history</p>
                  </div>
                  <div className="col-lg-6 px-0">
                    <WalletRow />
                  </div>
                </div>
              </div>
            </Welcome>
          </div>
          <div className="col-lg-4">
            <div className="box px-5 py-4">
              <p className="mb-0">Yearly - Affiliate Activation</p>
              <h2>${subscription.annual}/Year</h2>
              {/* <p className="small">Due on: April 12th, 2024</p> */}
              <p className="small">Due on: {subscription.annual_due}</p>
              <div className="infos d-flex align-items-start">
                <img src={checkmark} alt="checkmark" />
                <small className="d-block mt-n1">
                  Includes access to affiliate program matrix, tools and
                  training
                </small>
              </div>
            </div>
          </div>
          <div className="col-lg-4">
            <div className="box px-5 py-4">
              <p className="mb-0">Monthly Subscription</p>
              <h2>${subscription.monthly}/Month</h2>
              {/* <p className="small">Due on: April 12th</p> */}
              <p className="small">Due on: {subscription.monthly_due}</p>
              <div className="infos d-flex align-items-start">
                <img src={checkmark} alt="checkmark" />
                <small className="d-block mt-n1">
                  Includes access to all current and future products & $50 worth
                  of PRFTI Token Credits
                </small>
              </div>
            </div>
          </div>
          <div className="col-lg-4">
            <div className="box px-5 py-4 last">
              <p className="mb-0">Wallet balance from earnings</p>
              <h2>${subscription.balance}</h2>
              <div className="infos d-flex align-items-start errors">
                {/* <img src={xCircle} alt="xCircle" /> */}
                <small className="d-block mt-n1">
                  {/* Not enough to pay subscription */}
                  {subBalance >= amtNeeded
                    ? '\u00A0'
                    : 'Not enough to pay subscription'}
                </small>
                {/* <img src={info} alt="info" /> */}
              </div>
              <button
                type="button"
                className="btn btn-fund-wallet"
                onClick={handleClickFundWallet}
              >
                Fund Wallet
              </button>
            </div>
          </div>
          <div className="col-sm-11 col-lg-12 mt-4">
            <div className="bg-gray table p-2 p-lg-4">
              <Table
                title="Billing History"
                columns={columns}
                data={billings}
                searchable
                onSearch={handleSearch}
                date
                initialDate={startDate}
                finalDate={endDate}
                onChangeStartDate={handleChangeStartDate}
                onChangeLastDate={handleChangeLastDate}
                exportable
                pagination
              />
            </div>
          </div>
        </div>
      </div>

      <Modal
        size="xl"
        show={show}
        onHide={handleClose}
        className="modal-wrong-network"
      >
        <button
          type="button"
          className="h4 modal-close m-3 mb-0 ms-auto border-0 bg-transparent"
          onClick={handleClose}
        >
          x
        </button>
        <Modal.Header className="justify-content-center border-0 pb-0 pb-lg-4 pt-5">
          <div className="my-n5 d-flex align-items-center justify-content-center overflow-hidden">
            <Lottie
              options={{
                animationData: stakingTerms,
                autoplay: true,
                loop: true,
                rendererSettings: {
                  preserveAspectRatio: 'xMidYMid slice',
                },
              }}
              height={220}
              width={300}
            />
          </div>
        </Modal.Header>
        <Modal.Body className="px-4 px-sm-5">
          <h2 className="mb-4 fw-bold text-center w-100">
            Not enough to pay subscription?
          </h2>
          <div className="texts w-75 mx-auto">
            <p className="text-center mb-4">
              Remember to check your Revenue Share Rewards account and the
              Performance pool. If you qualify for them, likely there could be
              funds waiting for you there.
            </p>
            <div className="d-flex terms justify-content-between px-5 mb-2">
              <div className="d-flex justify-content-between w-100 me-4">
                <span>PROFITi Revenue Share Pool Available Rewards:</span>
                <span className="color-span">10 USDPI</span>
              </div>
              <button type="button" className="claim px-3 py-2 border-0">
                Claim
              </button>
            </div>

            <div className="d-flex terms justify-content-between px-5 mb-2">
              <div className="d-flex justify-content-between w-100 me-4">
                <span>Performance Pool Available Rewards:</span>
                <span className="color-span">20 USDPI</span>
              </div>
              <button type="button" className="claim px-3 py-2 border-0">
                Claim
              </button>
            </div>

            <p className="text-center mt-4">
              Amount Needed: <b>60 USDPI</b>
            </p>

            <button
              type="button"
              onClick={() => {
                setShowFinish(true);
              }}
              className="btn-confirm w-100 my-4"
            >
              Fund Wallet{' '}
              <HiArrowNarrowRight className="ms-3" color="#fff" size={28} />
            </button>
            <button
              type="button"
              onClick={handleClose}
              className="btn-done w-100 mb-3"
            >
              <span className="">DONE</span>
            </button>
          </div>
        </Modal.Body>
        <Modal.Footer className="border-0 py-4" />
      </Modal>

      <Modal
        show={showFundAccountWallet}
        onHide={handleClose}
        className="modal-wrong-network"
      >
        <Form onSubmit={handleSubmit}>
          <button
            type="button"
            className="h4 modal-close m-3 mb-0 ms-auto border-0 bg-transparent"
            onClick={handleClose}
          >
            x
          </button>
          <Modal.Header className="justify-content-center border-0 pb-0 pb-lg-4 pt-5">
            <div className="my-n5 d-flex align-items-center justify-content-start overflow-hidden w-100 px-4">
              <Modal.Title className="pe-3 fw-semibold">
                Fund Account Wallet
              </Modal.Title>
            </div>
          </Modal.Header>
          <Modal.Body className="px-4 px-sm-5">
            <label className="d-block w-100">
              <span className="d-block mb-3">Amount Needed: $15</span>
              <Input
                name="fund"
                placeholder="Enter amount you wish to fund wallet here"
                className="input"
              />
            </label>
          </Modal.Body>
          <Modal.Footer className="border-0 py-4 justify-content-center">
            <button
              type="button"
              className="btn btn-cancel me-3"
              onClick={handleClose}
            >
              Cancel
            </button>
            <button type="submit" className="btn btn-fund-wallet">
              Fund Wallet
            </button>
          </Modal.Footer>
        </Form>
      </Modal>

      <ModalFinish
        size="xl"
        show={showFinish}
        onHide={handleClose}
        className="modal-wrong-network"
      >
        <button
          type="button"
          className="h4 modal-close m-3 mb-0 ms-auto border-0 bg-transparent"
          onClick={handleClose}
        >
          x
        </button>
        <ModalFinish.Header className="justify-content-center border-0 pb-0 pb-lg-4 pt-5">
          <div className="my-n5 d-flex align-items-center justify-content-center overflow-hidden">
            <Lottie
              options={{
                animationData: earningsDone,
                autoplay: true,
                loop: false,
                rendererSettings: {
                  preserveAspectRatio: 'xMidYMid slice',
                },
              }}
              height={220}
              width={300}
            />
          </div>
        </ModalFinish.Header>
        <ModalFinish.Body className="px-4 px-sm-5">
          <h2 className="mb-4 fw-bold text-center w-100">
            Wallet Successfully Funded!
          </h2>
          <div className="texts w-75 mx-auto">
            <p className="text-center mb-4 px-xl-5">
              Your account wallet was successfully funded. If you have a balance
              due for your subscription, the amount funded will be applied
              towards it.
            </p>
            <p className="text-center">Here is the transaction hash:</p>
            <div className="mb-5">
              <a
                href={`${explorerURL}/tx/${withdrawalResult}`}
                target="_blank"
                rel="noreferrer"
              >
                0x26fb42f19c9ab0711d18ba486ae1d55aa2df8b1f8774d935597aaf9a12cade98
              </a>
            </div>
            <p className="mb-4">
              Use the buttons below to add the Token contract to your MetaMask
              if you have not done it yet.
            </p>
          </div>
        </ModalFinish.Body>
        <ModalFinish.Footer className="border-0 py-4" />
      </ModalFinish>

      <Loading
        type="dark"
        srcImg={logoWhite}
        text="PLEASE WAIT..."
        active={loading}
      />
    </Container>
  );
};

export default Referrers;
